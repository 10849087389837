import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { cafeService } from 'api/services/cafeService';
import { entradaService } from 'api/services/entradaService';
import { fermentacionService } from 'api/services/fermentacionService';
import ConfirmModal from 'components/modal/ConfirmModal';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IoList } from 'react-icons/io5';
import { LuActivitySquare, LuMicroscope } from 'react-icons/lu';
import { SlChemistry } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cafeActions } from 'store/cafe';
import {
  fetchData,
  handleCloseConfirmModal,
  handleCloseModal,
} from 'utils/hooks';
import FermentacionModule from 'views/admin/humedo/fermentacion/components/FermentacionModule';
import FermentacionModuleMultiple from 'views/admin/humedo/fermentacion/components/FermentacionModuleMultiple';
import {
  fieldsDespulpadora,
  fieldsHumedad,
  fieldsLaboratorio,
  fieldsMicroorganismos,
  fieldsProceso,
} from 'views/admin/humedo/fermentacion/variables/fields';
import { ALERT } from '../../../../constant/constant';
import Descarga from './components/Descarga';

export default function FermentacionRegistro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  const location = useLocation();
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const storeMicroorganismo = useSelector((state) => state.cafe.microorganismo);
  const { selectedFermentacion } = location.state || {};
  const initialFermentacionState = selectedFermentacion
    ? selectedFermentacion
    : {
        entradaId: '',
        excluidos: [],
        observaciones: '',
        laboratorio: {
          fecha: dayjs(),
          hora: dayjs(),
          microorganismos: [],
        },
        proceso: {},
        despulpadora: { hora: dayjs() },
        microorganismos: [],
        humedad: {},
      };
  const [entradas, setEntradas] = useState([]);
  const [confirm, setConfirm] = useState({ state: false });
  const [fermentaciones, setFermentaciones] = useState([]);
  const [fermentacion, setFermentacion] = useState(initialFermentacionState);
  const [refetch, setRefetch] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    fetchData({
      requests: [
        // Microorganismo request
        {
          apiCall: () => cafeService.getAllMicroorganismo(TOKEN),
          onSuccess: (data) =>
            dispatch(
              cafeActions.setMicroorganismoData({
                microorganismo: data,
              }),
            ),
          dataTransform: (data) => data?.data?.data || [],
        },
        // Entradas request
        {
          apiCall: () => entradaService.getAllEntradas(TOKEN),
          onSuccess: (data) => {
            const cerezaEntradaIds = data
              .filter((entrada) => entrada.caracteristica === 'Cereza')
              .map((entrada) => entrada.entradaId);

            const filteredEntradas = data.filter((entrada) =>
              cerezaEntradaIds.includes(entrada.entradaId),
            );
            setEntradas(filteredEntradas);
            if (selectedFermentacion?.registerId) {
              const selectedEntrada = data.find(
                (x) => x.registerId === selectedFermentacion?.registerId,
              );
              setFermentacion((prev) => ({
                ...prev,
                pesoTotal: selectedEntrada.pesoNeto,
                registerId: selectedEntrada.registerId,
                entradaId: selectedEntrada.entradaId,
                productor: selectedEntrada.productor,
                estado: selectedEntrada.estado,
                region: selectedEntrada.region,
                municipio: selectedEntrada.municipio,
                comunidad: selectedEntrada.comunidad,
                cosecha: selectedEntrada.cosecha,
              }));
            }
          },
          dataTransform: (data) => data?.data?.data || [],
        },
        // Fermentacion request
        {
          apiCall: async () =>
            await fermentacionService.getAllFermentacion(TOKEN),
          onSuccess: (data) => setFermentaciones(data),
          dataTransform: (data) => data?.data?.data || [],
        },
      ],
      setMessage,
      alertMessages: ALERT,
      setIsLoading,
    });
  }, [TOKEN, refetch, setIsLoading, selectedFermentacion?.registerId]);

  const handleChange = (category, field, value, id = null) => {
    setFermentacion((prevState) => {
      if (id !== null) {
        return {
          ...prevState,
          [category]: prevState[category].map((entry) =>
            entry.id === id ? { ...entry, [field]: value } : entry,
          ),
        };
      }
      return {
        ...prevState,
        [category]: { ...prevState[category], [field]: value },
      };
    });
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitted(true);
      const response = await fermentacionService.addFermentacion(
        TOKEN,
        USERID,
        fermentacion,
      );
      if (response.status === 200) {
        setIsSubmitted(false);
        setRefetch((prevState) => !prevState);
        handleMessage('success', ALERT.SUCCESS_TITLE, ALERT.SUCCESS_SUBTITLE);
        window.scrollTo({ top: 0, left: 0 });
      } else {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
      }
    } catch (error) {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Descarga
              entradas={entradas}
              fermentacion={fermentacion}
              fermentaciones={fermentaciones}
              setFermentacion={setFermentacion}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            direction={'column'}
            gap={'20px'}
            gridArea={{ base: '2 / 1 / 3 / 3' }}
          >
            <FermentacionModule
              title={'Solicitud a laboratorio'}
              icon={SlChemistry}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsLaboratorio(storeMicroorganismo)}
              fermentacion={fermentacion}
            />
            <FermentacionModule
              title={'Orden de proceso'}
              icon={IoList}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsProceso}
              fermentacion={fermentacion}
            />
            <FermentacionModuleMultiple
              title={'Adición de microorganismos fermentadores'}
              icon={LuMicroscope}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsMicroorganismos(storeMicroorganismo)}
              category={'microorganismos'}
              fermentacion={fermentacion}
              setFermentacion={setFermentacion}
              setConfirm={setConfirm}
              multiple={true}
            />
            <FermentacionModule
              title={'Proceso de despulpadora'}
              icon={LuActivitySquare}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsDespulpadora}
              fermentacion={fermentacion}
            />
            <FermentacionModule
              title={'Humedad'}
              icon={WaterDropIcon}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsHumedad}
              category={'humedad'}
              fermentacion={fermentacion}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={() => handleCloseModal(setMessage)}
        message={message}
      />
      <ConfirmModal
        isOpen={confirm.state}
        onClose={() => handleCloseConfirmModal(setConfirm)}
        confirm={confirm}
      />
    </Flex>
  );
}
