import {
  apiClient,
  getAuthHeaders,
  handleServiceError,
} from 'api/config/config';

// Helper functions

// Fermentacion Services
export const fermentacionService = {
  getAllFermentacion: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/fermentacion/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addFermentacion: async (token, userId, fermentacion) => {
    try {
      return await apiClient.post(
        'client/api/v1/fermentacion/create',
        {
          registerId: fermentacion.registerId,
          proceso: fermentacion.proceso
            ? JSON.stringify(fermentacion.proceso)
            : null,
          laboratorio: fermentacion.laboratorio
            ? JSON.stringify(fermentacion.laboratorio)
            : null,
          microorganismos: fermentacion.microorganismos
            ? JSON.stringify(fermentacion.microorganismos)
            : null,
          despulpadora: fermentacion.despulpadora
            ? JSON.stringify(fermentacion.despulpadora)
            : null,
          humedad: fermentacion.humedad
            ? JSON.stringify(fermentacion.humedad)
            : null,
          createdAt: new Date(),
          createdBy: userId,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
};
