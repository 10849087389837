import {
  Flex,
  FormLabel,
  InputGroup,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Dropdown({ field, catacion, setCatacion }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction="column" key={field.value}>
      <FormLabel
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        mb="8px"
      >
        {field.label}
      </FormLabel>
      <InputGroup>
        <Select
          value={catacion.calificaciones?.[field.value]}
          onChange={(e) => {
            setCatacion((prev) => ({
              ...prev,
              calificaciones: {
                ...prev.calificaciones,
                [field.value]: e.target.value,
              },
            }));
          }}
          variant="auth"
          cursor="pointer"
        >
          {field.options.map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </Select>
      </InputGroup>
    </Flex>
  );
}
