import { Flex, Grid, Icon, useColorModeValue } from '@chakra-ui/react';
import { cafeService } from 'api/services/cafeService';
import { entradaService } from 'api/services/entradaService';
import { origenService } from 'api/services/origenService';
import ConfirmModal from 'components/modal/ConfirmModal';
import ResultMessage from 'components/modal/ResultMessage';
import PrintableContent from 'components/printable/entradas/PrintableContent';
import { VSeparator } from 'components/separator/Separator';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { IoSendOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { cafeActions } from 'store/cafe';
import { origenActions } from 'store/origen';
import { getCosecha } from 'utils/format';
import { fetchData } from 'utils/hooks';
import Peso from 'views/admin/seco/entradas/components/Peso por unidad o transporte/Peso';
import Proveedor from 'views/admin/seco/entradas/components/Proveedor/Proveedor';

export default function Registro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { selectedEntrada, previewMode, editMode } = location.state || {};

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  const { productor, origen } = useSelector((state) => state.origen);
  const { tipo, caracteristica } = useSelector((state) => state.cafe);

  const initialWeightEntriesState =
    editMode && selectedEntrada?.length > 0
      ? selectedEntrada
      : [
          {
            id: 1,
            chofer: 'Jorge Cruz Vázquez',
            placas: 'RY-30-570',
            marca: 'Ford',
            unidad: '1',
            cumple: 'Cumple',
            caracteristica: 'Cereza',
            ticketBascula: '',
            pesoConCarga: '0.00',
            pesoSinCarga: '0.00',
            yutes: '0',
            cajas: '0',
            lonas: '0',
            tara: '0',
            pesoBruto: '0.00',
            pesoNeto: '0.00',
            pesoPorSaco: '0.00',
            limiteHumedad: '11.00',
            humedad: '11.00',
            excedenteHumedad: '0.00',
            descuentoHumedad: '0.00',
            descuentoHumedadPeso: '0.00',
            precioPrevio: '0.00',
            precioKg: '0.00',
            precioTotal: '0.00',
            observaciones: '',
          },
        ];

  const initialEntradaState =
    editMode && selectedEntrada?.length > 0
      ? {
          entradaId: selectedEntrada[0].entradaId,
          fecha: selectedEntrada[0].fecha,
          estado: selectedEntrada[0].estado,
          region: selectedEntrada[0].region,
          municipio: selectedEntrada[0].municipio,
          comunidad: selectedEntrada[0].comunidad,
          productor: selectedEntrada[0].productor,
          cosecha: selectedEntrada[0].cosecha,
          tipo: selectedEntrada[0].tipo,
        }
      : {
          entradaId: '',
          fecha: new Date(),
          estado: '',
          region: '',
          municipio: '',
          comunidad: '',
          productor: '',
          cosecha: getCosecha(),
          tipo: '',
        };

  const [weightEntries, setWeightEntries] = useState(initialWeightEntriesState);
  const [entrada, setEntrada] = useState(initialEntradaState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState({ state: false });
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const requests = [
      {
        apiCall: () => origenService.getAllOrigen(TOKEN),
        onSuccess: (data) =>
          dispatch(origenActions.setOrigenData({ origen: data })),
        dataTransform: (data) => data?.data?.data || [],
      },
      {
        apiCall: () => origenService.getAllProductor(TOKEN),
        onSuccess: (data) =>
          dispatch(origenActions.setProductorData({ productor: data })),
        dataTransform: (data) => data?.data?.data || [],
      },
      {
        apiCall: () => cafeService.getAllTipo(TOKEN),
        onSuccess: (data) => dispatch(cafeActions.setTipoData({ tipo: data })),
        dataTransform: (data) => data?.data?.data || [],
      },
      {
        apiCall: () => cafeService.getAllCaracteristica(TOKEN),
        onSuccess: (data) =>
          dispatch(
            cafeActions.setCaracteristicaData({
              caracteristica: data,
            }),
          ),
        dataTransform: (data) => data?.data?.data || [],
      },
    ];

    if (!editMode) {
      requests.push({
        apiCall: () => entradaService.getLatestId(TOKEN),
        onSuccess: (data) => {
          const latestId = data?.length > 0 ? data[0].entradaId : 0;
          if (latestId != null) {
            setEntrada((prev) => ({
              ...prev,
              entradaId: latestId + 1,
            }));
          }
        },
        dataTransform: (data) => data?.data?.data || [],
      });
    }

    fetchData({
      requests,
      setMessage,
      alertMessages: ALERT,
      isLoading,
    });
  }, [TOKEN, dispatch, editMode]);

  const handleWeightEntryChange = (field, value, id) => {
    setWeightEntries((prevState) => {
      return prevState.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const handleChange = (field, value) => {
    setEntrada((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleCloseConfirmModal = () => {
    setConfirm({ state: false });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every(
        (value) => value !== null && value !== undefined && value !== '',
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirmSubmit = async () => {
      setConfirm({ state: false });
      const exceptions = [
        'observaciones',
        'lonas',
        'yutes',
        'tara',
        'cajas',
        'descuentoHumedad',
        'descuentoHumedadPeso',
        'createdBy',
        'updatedBy',
        'isDeleted',
        'precioPrevio',
      ];

      const allEntradaPropertiesTruthy = handleAllTruthy(entrada, exceptions);
      const allWeightEntriesPropertiesTruthy = handleAllTruthy(
        weightEntries,
        exceptions,
      );

      if (allEntradaPropertiesTruthy && allWeightEntriesPropertiesTruthy) {
        try {
          setIsLoading(true);
          setIsSubmitted(true);

          const totalYutes = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.yutes || 0), 0)
            .toFixed(2);
          const totalLonas = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.lonas || 0), 0)
            .toFixed(2);
          const totalCajas = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.cajas || 0), 0)
            .toFixed(2);
          const totalTara = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.tara || 0), 0)
            .toFixed(2);
          const totalPrecioPrevio = weightEntries
            .reduce(
              (sum, entry) => sum + parseFloat(entry.precioPrevio || 0),
              0,
            )
            .toFixed(2);
          const totalDescuentoHumedadPeso = weightEntries
            .reduce(
              (sum, entry) => sum + parseFloat(entry.descuentoHumedadPeso || 0),
              0,
            )
            .toFixed(2);
          const totalPrecioTotal = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.precioTotal || 0), 0)
            .toFixed(2);
          const totalDescuentoHumedad = weightEntries
            .reduce(
              (sum, entry) => sum + parseFloat(entry.descuentoHumedad || 0),
              0,
            )
            .toFixed(2);
          const totalPesoConCarga = weightEntries
            .reduce(
              (sum, entry) => sum + parseFloat(entry.pesoConCarga || 0),
              0,
            )
            .toFixed(2);
          const totalPesoSinCarga = weightEntries
            .reduce(
              (sum, entry) => sum + parseFloat(entry.pesoSinCarga || 0),
              0,
            )
            .toFixed(2);
          const totalPesoNeto = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.pesoNeto || 0), 0)
            .toFixed(2);
          const totalPesoBruto = weightEntries
            .reduce((sum, entry) => sum + parseFloat(entry.pesoBruto || 0), 0)
            .toFixed(2);

          const totalPesoPorSaco =
            totalPesoNeto > 0
              ? (
                  totalPesoNeto /
                  (parseFloat(totalYutes) +
                    parseFloat(totalLonas) +
                    parseFloat(totalCajas))
                ).toFixed(2)
              : 0;
          const totalPrecioKg =
            totalPesoNeto > 0
              ? (
                  totalPrecioTotal /
                  (totalPesoNeto - totalDescuentoHumedadPeso)
                ).toFixed(2)
              : 0;

          const totalCumple = weightEntries.every(
            (entry) => entry.cumple === 'Cumple',
          )
            ? 'Cumple'
            : 'No cumple';

          const entradaEntryTotal = {
            ...entrada,
            registerId: entrada.entradaId + '.0',
            cajas: parseFloat(totalCajas) || 0,
            lonas: parseFloat(totalLonas) || 0,
            yutes: parseFloat(totalYutes) || 0,
            tara: parseFloat(totalTara) || 0,
            pesoBruto: parseFloat(totalPesoBruto) || 0,
            pesoNeto: parseFloat(totalPesoNeto) || 0,
            descuentoHumedad: parseFloat(totalDescuentoHumedad) || 0,
            descuentoHumedadPeso: parseFloat(totalDescuentoHumedadPeso) || 0,
            precioPrevio: parseFloat(totalPrecioPrevio) || 0,
            precioTotal: parseFloat(totalPrecioTotal) || 0,
            pesoConCarga: parseFloat(totalPesoConCarga) || 0,
            pesoSinCarga: parseFloat(totalPesoSinCarga) || 0,
            pesoPorSaco: parseFloat(totalPesoPorSaco) || 0,
            precioKg: parseFloat(totalPrecioKg) || 0,
            cumple: totalCumple,
          };

          const updatedWeightEntries = [entradaEntryTotal, ...weightEntries];
          const submitEntradas = [];

          for (const entry of updatedWeightEntries) {
            const submitEntrada = { ...entry, ...entrada };
            submitEntradas.push(submitEntrada);
          }

          const response = await entradaService.addAllEntradas(
            TOKEN,
            submitEntradas,
            editMode,
          );

          if (response.status === 200) {
            setIsSubmitted(false);
            setIsLoading(false);
            handleMessage(
              'success',
              ALERT.SUCCESS_TITLE,
              ALERT.SUCCESS_SUBTITLE,
            );
            if (!editMode) {
              setWeightEntries(initialWeightEntriesState);
              setEntrada((prevState) => ({
                ...initialEntradaState,
                entradaId: prevState.entradaId + 1,
              }));
            } else {
              navigate(`/admin/humedo/entradas/base-de-datos`);
            }
            window.scrollTo({ top: 0, left: 0 });
          } else {
            setIsLoading(false);
            handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
          }
        } catch (error) {
          setIsLoading(false);
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
          console.error(error);
        }
      } else {
        setIsSubmitted(true);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_FIELDS);
      }
    };

    setConfirm({
      state: true,
      title: 'Confirmar registro',
      subtitle: `¿Estás seguro de que deseas registrar la entrada?`,
      icon: <Icon as={IoSendOutline} w="24px" h="24px" />,
      primaryBtnText: 'Registrar',
      primaryBtnAction: confirmSubmit,
      secondaryBtnText: 'Cancelar',
      secondaryBtnAction: () => setConfirm({ state: false }),
    });
  };

  return !previewMode ? (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Peso
              key={entrada.entradaId}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleWeightEntryChange={handleWeightEntryChange}
              weightEntries={weightEntries}
              setWeightEntries={setWeightEntries}
              caracteristica={caracteristica}
              editMode={editMode}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              setConfirm={setConfirm}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Proveedor
              entrada={entrada}
              setEntrada={setEntrada}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
              origen={origen}
              productor={productor}
              tipo={tipo}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
      <ConfirmModal
        isOpen={confirm.state}
        onClose={handleCloseConfirmModal}
        confirm={confirm}
      />
    </Flex>
  ) : (
    <PrintableContent
      entrada={entrada}
      selectedEntrada={selectedEntrada}
      weightEntries={weightEntries}
    />
  );
}
