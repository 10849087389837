import {
  apiClient,
  getAuthHeaders,
  handleServiceError,
} from 'api/config/config';

// Origen Services
export const origenService = {
  getAllOrigen: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/origen/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true, isDeleted: false },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  getAllProductor: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/productor/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true, isDeleted: false },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addOrigen: async (token, userId, origen) => {
    try {
      return await apiClient.post(
        'client/api/v1/origen/create',
        {
          estado: origen.estado,
          region: origen.region,
          municipio: origen.municipio,
          comunidad: origen.comunidad,
          createdAt: new Date(),
          createdBy: userId,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addProductor: async (token, userId, productor) => {
    const now = new Date();
    const dateString = now.toLocaleString();
    try {
      return await apiClient.post(
        'client/api/v1/origen/create',
        {
          productor: productor.productor,
          representante: productor.representante,
          estado: productor.estado,
          region: productor.region,
          municipio: productor.municipio,
          comunidad: productor.comunidad,
          programaFertilizante: productor.programaFertilizante,
          clavePNC: productor.clavePNC,
          fechaRegistro: dateString,
          createdAt: now,
          createdBy: userId,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
};
