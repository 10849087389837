import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// React
import { useState } from 'react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import dayjs from 'dayjs';
import { MdCenterFocusWeak } from 'react-icons/md';
import { safeJsonParse } from 'utils/format';

export default function Descarga({
  entradas,
  fermentacion,
  fermentaciones,
  setFermentacion,
  isSubmitted,
  handleFocus,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const [selectedEntries, setSelectedEntries] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const toggleShow = () => {
    setCollapsed(!collapsed);
  };

  const uniqueEntradas = entradas
    .filter(
      (entry, index, self) =>
        index === self.findIndex((e) => e.entradaId === entry.entradaId),
    )
    .sort((a, b) => b.entradaId - a.entradaId);

  const sumTotalBySpecificId = (specificId) => {
    const total = entradas
      .filter(
        (obj) =>
          +obj.entradaId === +specificId && !obj.registerId.includes('.0'),
      )
      .reduce(
        (accumulator, currentObject) => accumulator + +currentObject.pesoNeto,
        0,
      );

    return total;
  };

  const handleEntradaSelection = (e) => {
    if (!e.target.value) {
      return;
    }

    const entradaId = e ? e.target.value.trim() : fermentacion.entradaId;
    const selectedEntrada = entradas.find(
      (x) => x.registerId === `${entradaId}.0`,
    );
    const selectedFermentacion =
      fermentaciones.find((x) => x.registerId === selectedEntrada.registerId) ||
      {};
    const selectedEntries = entradas.filter(
      (obj) =>
        +obj.entradaId === +entradaId &&
        !obj.registerId.includes('.0') &&
        obj.registerId.startsWith(entradaId + '.'),
    );

    setSelectedEntries(selectedEntries);

    const totalWeight = e
      ? sumTotalBySpecificId(entradaId)
      : fermentacion.pesoTotal;

    setFermentacion((prevState) => ({
      ...prevState,
      proceso: safeJsonParse(selectedFermentacion?.proceso),
      humedad: safeJsonParse(selectedFermentacion?.humedad),

      microorganismos: selectedFermentacion?.microorganismos
        ? safeJsonParse(selectedFermentacion.microorganismos)
        : prevState.microorganismos.map((entry) => ({
            ...entry,
            hora: dayjs(),
            kilos: totalWeight,
          })),

      laboratorio: selectedFermentacion?.laboratorio
        ? safeJsonParse(selectedFermentacion.laboratorio)
        : { ...prevState.laboratorio, kilos: totalWeight },

      despulpadora: selectedFermentacion?.despulpadora
        ? safeJsonParse(selectedFermentacion.despulpadora)
        : { ...prevState.despulpadora, kilos: totalWeight },

      pesoTotal: totalWeight,
      registerId: selectedEntrada.registerId,
      entradaId: selectedEntrada.entradaId,
      productor: selectedEntrada.productor,
      estado: selectedEntrada.estado,
      region: selectedEntrada.region,
      municipio: selectedEntrada.municipio,
      comunidad: selectedEntrada.comunidad,
      cosecha: selectedEntrada.cosecha,
    }));
  };

  // const handleSelectedEntries = (e, entry) => {
  //   const isChecked = e.target.checked;
  //   const excluidos = isChecked
  //     ? fermentacion.excluidos.filter((id) => id !== entry.registerId)
  //     : [...fermentacion.excluidos, entry.registerId];

  //   const totalWeight = isChecked
  //     ? (parseFloat(fermentacion.pesoTotal) || 0) +
  //       (parseFloat(entry.pesoNeto) || 0)
  //     : (parseFloat(fermentacion.pesoTotal) || 0) -
  //       (parseFloat(entry.pesoNeto) || 0);

  //   setFermentacion((prevState) => ({
  //     ...prevState,
  //     excluidos: excluidos,
  //     pesoTotal: totalWeight,
  //   }));
  // };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        minH={'80px'}
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Selección de entrada
            </Text>
          </Flex>
          <Flex gap={'30px'} alignItems={'center'}>
            <Tooltip
              hasArrow
              label={collapsed ? 'Contraer' : 'Expandir'}
              bg="gray.300"
              color="black"
            >
              <Button onClick={toggleShow} w={'56px'}>
                {collapsed ? (
                  <ChevronDownIcon fontSize="1.2rem" />
                ) : (
                  <ChevronUpIcon fontSize="1.2rem" />
                )}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      {collapsed && (
        <FormControl>
          <SimpleGrid
            columns={{ base: '1', md: '3', lg: '3' }}
            gap={{ sm: '10px', md: '26px' }}
            mb={'1rem'}
          >
            <Flex direction="column">
              <Flex alignItems={'center'}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Entrada ID
                </FormLabel>
              </Flex>
              <Select
                borderColor={
                  isSubmitted && !fermentacion.entradaId ? 'red.500' : null
                }
                value={fermentacion.entradaId || ''}
                onChange={(e) => {
                  handleEntradaSelection(e);
                }}
                onFocus={handleFocus}
                variant="auth"
                placeholder="Entrada ID"
              >
                <option value="" disabled hidden>
                  {fermentacion.entradaId || 'Entrada ID'}
                </option>
                {uniqueEntradas.length > 0 &&
                  uniqueEntradas.map((x) => (
                    <option key={x.id} value={x.entradaId}>
                      {x.entradaId}
                    </option>
                  ))}
              </Select>
            </Flex>
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Kilogramos totales
              </FormLabel>
              <InputGroup>
                <Input
                  disabled
                  value={fermentacion.pesoTotal}
                  borderRadius="6px"
                  height="40px"
                  fontSize="md"
                  placeholder="Kilogramos totales"
                  variant="auth"
                  size="lg"
                />
              </InputGroup>
            </Flex>
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Cosecha
              </FormLabel>
              <InputGroup>
                <Input
                  disabled
                  borderColor={
                    isSubmitted && !fermentacion.cosecha ? 'red.500' : null
                  }
                  value={fermentacion.cosecha}
                  borderRadius="6px"
                  height="40px"
                  fontSize="md"
                  placeholder="Cosecha"
                  variant="auth"
                  size="lg"
                />
              </InputGroup>
            </Flex>
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Productor
              </FormLabel>
              <InputGroup>
                <Input
                  disabled
                  borderColor={
                    isSubmitted && !fermentacion.productor ? 'red.500' : null
                  }
                  value={fermentacion.productor}
                  borderRadius="6px"
                  height="40px"
                  fontSize="md"
                  placeholder="Productor"
                  variant="auth"
                  size="lg"
                />
              </InputGroup>
            </Flex>
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Región
              </FormLabel>
              <InputGroup>
                <Input
                  disabled
                  borderColor={
                    isSubmitted && !fermentacion.region ? 'red.500' : null
                  }
                  value={fermentacion.region}
                  borderRadius="6px"
                  height="40px"
                  fontSize="md"
                  placeholder="Región"
                  variant="auth"
                  size="lg"
                />
              </InputGroup>
            </Flex>
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Municipio
              </FormLabel>
              <InputGroup>
                <Input
                  disabled
                  borderColor={
                    isSubmitted && !fermentacion.municipio ? 'red.500' : null
                  }
                  value={fermentacion.municipio}
                  borderRadius="6px"
                  height="40px"
                  fontSize="md"
                  placeholder="Municipio"
                  variant="auth"
                  size="lg"
                />
              </InputGroup>
            </Flex>
          </SimpleGrid>
          {/* <SimpleGrid
            columns={{ base: '1' }}
            gap={{ sm: '10px', md: '26px' }}
            mt={'1rem'}
          >
            {fermentacion.entradaId && (
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Subregistros seleccionados
                </FormLabel>
                <Stack spacing={5} direction="row">
                  {selectedEntries?.map((entry) => (
                    <Checkbox
                      key={entry.registerId}
                      onChange={(e) => handleSelectedEntries(e, entry)}
                      isChecked={
                        !fermentacion.excluidos.includes(entry.registerId)
                      }
                      color={textColor}
                    >
                      {entry.registerId} {`[${entry.pesoNeto} KG]`}
                    </Checkbox>
                  ))}
                </Stack>
              </Flex>
            )}
          </SimpleGrid> */}
        </FormControl>
      )}
    </Card>
  );
}
