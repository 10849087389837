import { Divider, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import Aromas from 'views/admin/seco/catacion/components/MuestrasPanel/components/Aromas';
import Calificaciones from 'views/admin/seco/catacion/components/MuestrasPanel/components/Calificaciones';
import Comunidad from 'views/admin/seco/catacion/components/MuestrasPanel/components/Comunidad';
import Header from 'views/admin/seco/catacion/components/MuestrasPanel/components/Header';

export default function MuestrasPanel({
  selectedFlavors,
  setSelectedFlavors,
  catacion,
  setCatacion,
  origen,
  handleFocus,
  isSubmitted,
  isLoading,
  handleSubmit,
  setCommunityModal,
  perfilsByCommunity,
  handleOpenScoreModal,
}) {
  const paleGray = useColorModeValue('secondaryGray.700', 'whiteAlpha.100');
  return (
    <Card maxHeight="85vh" overflowY="auto">
      <Header selectedFlavors={selectedFlavors} />
      <Comunidad
        catacion={catacion}
        setCatacion={setCatacion}
        origen={origen}
        handleFocus={handleFocus}
        isSubmitted={isSubmitted}
      />
      <Divider w="100%" alignSelf="center" my="30px" borderColor={paleGray} />
      <Calificaciones
        catacion={catacion}
        setCatacion={setCatacion}
        handleFocus={handleFocus}
        isSubmitted={isSubmitted}
        handleOpenScoreModal={handleOpenScoreModal}
      />
      <Divider w="100%" alignSelf="center" my="30px" borderColor={paleGray} />
      <Aromas
        selectedFlavors={selectedFlavors}
        setSelectedFlavors={setSelectedFlavors}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        setCommunityModal={setCommunityModal}
        perfilsByCommunity={perfilsByCommunity}
      />
    </Card>
  );
}
