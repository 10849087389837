import { Chip } from '@mui/material';

export const tableColumnsCatacion = [
  {
    headerName: 'Folio de secado',
    field: 'folioSecado',
    width: 150,
  },
  {
    headerName: 'Tipo',
    field: 'clasificacion',
    width: 150,
    renderCell: (params) =>
      params?.value ? (
        <Chip
          label={params.value.toUpperCase()}
          color={'default'}
          variant="solid"
        />
      ) : null,
  },
  {
    headerName: 'Entrada ID',
    field: 'entradaId',
    width: 100,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Clave de descarga',
    field: 'claveDescarga',
    width: 200,
  },
  {
    headerName: 'Perfiles',
    field: 'perfiles',
    dataType: 'perfil',
    width: 200,
  },
  {
    headerName: 'Puntaje',
    field: 'puntaje',
    width: 200,
  },
  {
    headerName: 'Calificación - Fragancia ',
    field: 'calificaciones.fragancia',
    width: 200,
  },
  {
    headerName: 'Calificación - Sabor ',
    field: 'calificaciones.sabor',
    width: 200,
  },
  {
    headerName: 'Calificación - Sabor Residual ',
    field: 'calificaciones.saborResidual',
    width: 200,
  },
  {
    headerName: 'Calificación - Ácidez ',
    field: 'calificaciones.acidez',
    width: 200,
  },
  {
    headerName: 'Calificación - Intesidad',
    field: 'calificaciones.intensidad',
    width: 200,
  },
  {
    headerName: 'Calificación - Cuerpo',
    field: 'calificaciones.cuerpo',
    width: 200,
  },
  {
    headerName: 'Calificación - Nivel',
    field: 'calificaciones.nivel',
    width: 200,
  },
  {
    headerName: 'Calificación - Uniformidad',
    field: 'calificaciones.uniformidad',
    width: 200,
  },
  {
    headerName: 'Calificación - Balance',
    field: 'calificaciones.balance',
    width: 200,
  },
  {
    headerName: 'Calificación - Taza',
    field: 'calificaciones.taza',
    width: 200,
  },
  {
    headerName: 'Calificación - Dulzor',
    field: 'calificaciones.dulzor',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    dataType: 'string',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Cliente',
    field: 'cliente',
    dataType: 'string',
    width: 200,
  },
];
