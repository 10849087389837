import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useState } from 'react';

// Custom components
import { extractNumericValue } from 'utils/format';

export default function Calificaciones({
  catacion,
  setCatacion,
  handleFocus,
  isSubmitted,
  handleOpenScoreModal,
}) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const buttonBgColor = useColorModeValue('gray.200', 'gray.600');
  const buttonActiveBgColor = useColorModeValue('gray.400', 'gray.800');
  const buttonColor = useColorModeValue('black', 'white');

  const [prefix, setPrefix] = useState('+');

  const handlePrefixChange = (newPrefix) => {
    setPrefix(newPrefix);
    setCatacion((prevState) => ({
      ...prevState,
      puntaje: `${newPrefix}${Math.abs(
        extractNumericValue(prevState.puntaje),
      ).toFixed(2)}`,
    }));
  };

  const handleKeyDown = (e) => {
    const currentValue = +catacion.puntaje;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setCatacion((prevState) => ({
        ...prevState,
        puntaje: `${prefix}${Math.abs(currentValue + 0.25).toFixed(2)}`,
      }));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setCatacion((prevState) => ({
        ...prevState,
        puntaje: `${prefix}${Math.abs(currentValue - 0.25).toFixed(2)}`,
      }));
    }
  };

  return (
    <FormControl>
      <Flex direction="column">
        <Text fontSize="xl" color={textColor} fontWeight="700" mb="15px">
          2 Calificaciones
        </Text>

        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            ms="4px"
            fontSize="16px"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="0px"
          >
            Puntaje del perfil
            <Flex gap="15px">
              <Text fontSize="12px" color={textColor}>
                *Mínimo: 73.00
              </Text>
              <Text fontSize="12px" color={textColor}>
                Máximo: 99.00
              </Text>
            </Flex>
          </FormLabel>
          <Flex>
            <Flex mr={2}>
              <Button
                onClick={() => handlePrefixChange('+')}
                bg={prefix === '+' ? buttonActiveBgColor : buttonBgColor}
                color={buttonColor}
                _hover={{ bg: buttonActiveBgColor }}
                borderRadius="6px"
              >
                Positivo +
              </Button>
              <Button
                onClick={() => handlePrefixChange('-')}
                bg={prefix === '-' ? buttonActiveBgColor : buttonBgColor}
                color={buttonColor}
                _hover={{ bg: buttonActiveBgColor }}
                borderRadius="6px"
                ml={2}
              >
                Negativo -
              </Button>
            </Flex>
            <Input
              borderColor={isSubmitted && !catacion.puntaje ? 'red.500' : null}
              value={catacion.puntaje}
              onChange={(e) => {
                setCatacion((prevState) => ({
                  ...prevState,
                  puntaje: `${prefix}${extractNumericValue(
                    e.target.value.trim(),
                  )}`,
                }));
              }}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Puntaje del perfil"
              variant="auth"
              size="lg"
            />
          </Flex>
        </Flex>

        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            ms="4px"
            fontSize="16px"
            fontWeight="500"
            color={textColor}
            mt="24px"
            mb="8px"
          >
            Calificaciones del perfil
          </FormLabel>
          <Button
            bg={buttonBgColor}
            color={buttonColor}
            _hover={{ bg: buttonActiveBgColor }}
            borderRadius="6px"
            onClick={handleOpenScoreModal}
          >
            Seleccionar calificaciones
          </Button>
        </Flex>
      </Flex>
    </FormControl>
  );
}
