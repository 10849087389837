import {
  Flex,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronUpIcon, QuestionIcon } from '@chakra-ui/icons';
import { ENTRADA } from 'constant/constant';
import { displayNumericString, extractNumericValue } from 'utils/format';

export default function Entrada({
  id,
  weightEntry,
  isSubmitted,
  handleFocus,
  handleWeightEntryChange,
  caracteristica,
  handlePeso,
  handlePesoBruto,
  handleTotal,
}) {
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'2rem'}
        px={'25px'}
      >
        <Flex alignItems="center" flexDirection="row" me="14px" gap="10px">
          <Text
            color="grey.500"
            fontSize="18px"
            fontWeight="700"
            lineHeight="100%"
          >
            Entrada de café
          </Text>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={{ base: '1', md: '3', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'20px'}
      >
        {/* Característica de café */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Característica de café{' '}
          </FormLabel>
          <Select
            borderColor={
              isSubmitted && !weightEntry.caracteristica ? 'red.500' : null
            }
            value={weightEntry.caracteristica}
            onChange={(e) =>
              handleWeightEntryChange('caracteristica', e.target.value, id)
            }
            onFocus={handleFocus}
            variant="auth"
            placeholder="Característica de café"
          >
            {caracteristica?.length > 0 &&
              caracteristica.map((x) => (
                <option key={x.id} value={x.caracteristica}>
                  {x.caracteristica}
                </option>
              ))}
          </Select>
        </Flex>
        {/* Número de ticket báscula */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Número de ticket báscula{' '}
          </FormLabel>
          <Input
            id="ticket"
            borderColor={
              isSubmitted && !weightEntry.ticketBascula ? 'red.500' : null
            }
            value={weightEntry.ticketBascula}
            onChange={(e) =>
              handleWeightEntryChange(
                'ticketBascula',
                e.target.value.trim(),
                id,
              )
            }
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Ticket báscula"
            variant="auth"
            size="lg"
          />
        </Flex>
        {/* Peso con carga */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso con carga{' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="pesoConCarga"
              borderColor={
                isSubmitted && !weightEntry.pesoConCarga ? 'red.500' : null
              }
              value={displayNumericString(weightEntry.pesoConCarga)}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightEntryChange('pesoConCarga', value, id);
                handlePesoBruto(value, 0);
                handlePeso(value, 0, 0, 0);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              isRequired={true}
              fontSize="md"
              placeholder="Peso con carga"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Peso sin carga */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso sin carga{' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="pesoSinCarga"
              borderColor={
                isSubmitted && !weightEntry.pesoSinCarga ? 'red.500' : null
              }
              value={displayNumericString(weightEntry.pesoSinCarga)}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightEntryChange('pesoSinCarga', value, id);
                handlePesoBruto(0, value);
                handlePeso(0, value, 0, 0);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso sin carga"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Peso bruto */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso bruto{' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="pesoBruto"
              disabled
              value={displayNumericString(weightEntry.pesoBruto)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso bruto"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Yutes */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Yutes
          </FormLabel>
          <Input
            id="yutes"
            disabled={weightEntry?.caracteristica.toLowerCase() === 'cereza'}
            value={weightEntry.yutes}
            onChange={(e) => {
              handleWeightEntryChange('yutes', e.target.value.trim(), id);
              handlePeso(0, 0, e.target.value.trim(), 0);
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Yutes"
            variant="auth"
            size="lg"
          />
        </Flex>
        {/* Lonas */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Lonas
          </FormLabel>
          <Input
            id="lonas"
            disabled={weightEntry?.caracteristica.toLowerCase() === 'cereza'}
            value={weightEntry.lonas}
            onChange={(e) => {
              handleWeightEntryChange('lonas', e.target.value.trim(), id);
              handlePeso(0, 0, 0, e.target.value.trim());
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Lonas"
            variant="auth"
            size="lg"
          />
        </Flex>
        {/* Cajas */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Cajas
          </FormLabel>
          <Input
            id="cajas"
            disabled={weightEntry?.caracteristica.toLowerCase() !== 'cereza'}
            value={weightEntry.cajas}
            onChange={(e) => {
              handleWeightEntryChange('cajas', e.target.value.trim(), id);
              handlePeso(0, 0, 0, 0, e.target.value.trim());
            }}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            fontSize="md"
            placeholder="Cajas (huacales)"
            variant="auth"
            size="lg"
          />
        </Flex>
        {/* Tara */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Tara
          </FormLabel>
          <InputGroup>
            <Input
              id="tara"
              disabled
              value={weightEntry.tara}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Tara"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Peso neto */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso neto
          </FormLabel>
          <InputGroup>
            <Input
              id="pesoNeto"
              disabled
              value={displayNumericString(weightEntry.pesoNeto)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso neto"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Peso por saco o caja */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Peso por saco o caja
          </FormLabel>
          <InputGroup>
            <Input
              id="pesoPorSaco"
              disabled
              value={displayNumericString(weightEntry.pesoPorSaco)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Peso por saco o caja"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG/saco</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Precio por KG */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Precio por KG{' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="precioKg"
              borderColor={
                isSubmitted && !weightEntry.precioKg ? 'red.500' : null
              }
              value={displayNumericString(weightEntry.precioKg)}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightEntryChange('precioKg', value, id);
                handleTotal(value, 0);
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Precio por KG"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>MXN</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Límite de humedad */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="center"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            gap="10px"
          >
            Límite de humedad
            <Tooltip
              label={ENTRADA.HUMEDAD_TOOLTIP}
              hasArrow
              placement="top"
              aria-label="A tooltip"
            >
              <QuestionIcon color={textColor} />
            </Tooltip>
          </FormLabel>
          <InputGroup>
            {/* Up & Down Arrows */}
            <Flex direction="column" alignItems="center">
              <IconButton
                icon={<ChevronUpIcon />}
                size="xs"
                aria-label="Increase humidity"
                onClick={() => {
                  const newValue =
                    (parseFloat(weightEntry.limiteHumedad) || 0) + 0.25;
                  handleWeightEntryChange(
                    'limiteHumedad',
                    newValue.toFixed(2),
                    id,
                  );
                  handleTotal(0, 0, 0, newValue);
                }}
              />
              <IconButton
                icon={<ChevronDownIcon />}
                size="xs"
                aria-label="Decrease humidity"
                onClick={() => {
                  const newValue =
                    (parseFloat(weightEntry.limiteHumedad) || 0) - 0.25;
                  handleWeightEntryChange(
                    'limiteHumedad',
                    newValue.toFixed(2),
                    id,
                  );
                  handleTotal(0, 0, 0, newValue);
                }}
              />
            </Flex>

            <Input
              id="humedad"
              type="number"
              step="0.25"
              borderColor={
                isSubmitted && !weightEntry.limiteHumedad ? 'red.500' : null
              }
              value={displayNumericString(weightEntry.limiteHumedad)}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightEntryChange('limiteHumedad', value, id);
                handleTotal(0, 0, 0, value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                  const newValue =
                    (parseFloat(weightEntry.limiteHumedad) || 0) +
                    (e.key === 'ArrowUp' ? 0.25 : -0.25);
                  handleWeightEntryChange(
                    'limiteHumedad',
                    newValue.toFixed(2),
                    id,
                  );
                  handleTotal(0, 0, 0, newValue);
                }
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Porcentaje de humedad"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>%</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Porcentaje de humedad */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="center"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            gap="10px"
          >
            Porcentaje de humedad
          </FormLabel>
          <InputGroup>
            {/* Up & Down Arrows */}
            <Flex direction="column" alignItems="center" mr="2px">
              <IconButton
                icon={<ChevronUpIcon />}
                size="xs"
                aria-label="Increase humidity"
                onClick={() => {
                  const newValue =
                    (parseFloat(weightEntry.humedad) || 0) + 0.25;
                  handleWeightEntryChange('humedad', newValue.toFixed(2), id);
                  handleTotal(0, newValue, 0);
                }}
              />
              <IconButton
                icon={<ChevronDownIcon />}
                size="xs"
                aria-label="Decrease humidity"
                onClick={() => {
                  const newValue =
                    (parseFloat(weightEntry.humedad) || 0) - 0.25;
                  handleWeightEntryChange('humedad', newValue.toFixed(2), id);
                  handleTotal(0, newValue, 0);
                }}
              />
            </Flex>

            <Input
              id="humedad"
              type="number"
              step="0.25"
              borderColor={
                isSubmitted && !weightEntry.humedad ? 'red.500' : null
              }
              value={displayNumericString(weightEntry.humedad)}
              onChange={(e) => {
                const value = extractNumericValue(e.target.value.trim());
                handleWeightEntryChange('humedad', value, id);
                handleTotal(0, value, 0);
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                  const newValue =
                    (parseFloat(weightEntry.humedad) || 0) +
                    (e.key === 'ArrowUp' ? 0.25 : -0.25);
                  handleWeightEntryChange('humedad', newValue.toFixed(2), id);
                  handleTotal(0, newValue, 0);
                }
              }}
              onFocus={handleFocus}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Porcentaje de humedad"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>%</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Excedente de humedad */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="center"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            gap="10px"
          >
            Excedente de humedad
          </FormLabel>
          <InputGroup>
            <Input
              id="humedad"
              isDisabled
              value={displayNumericString(weightEntry.excedenteHumedad)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Excedente de humedad"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>%</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Descuento de humedad */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Desc. de humedad (MXN){' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="descuentoHumedad"
              disabled
              value={displayNumericString(weightEntry.descuentoHumedad)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Descuento de humedad"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>MXN</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Descuento de humedad */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Desc. de humedad (KG){' '}
          </FormLabel>
          <InputGroup>
            <Input
              id="descuentoHumedadPeso"
              disabled
              value={displayNumericString(weightEntry.descuentoHumedadPeso)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Descuento de humedad (peso)"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>KG</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Precio previo (sin humedad) */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Precio previo (sin humedad)
          </FormLabel>
          <InputGroup>
            <Input
              id="precioPrevio"
              disabled
              value={displayNumericString(weightEntry.precioPrevio)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Precio previo (sin humedad)"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>MXN</InputRightAddon>
          </InputGroup>
        </Flex>
        {/* Total a pagar */}
        <Flex direction="column">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Total a pagar
          </FormLabel>
          <InputGroup>
            <Input
              id="precioTotal"
              disabled
              value={displayNumericString(weightEntry.precioTotal)}
              borderRadius="6px"
              height="40px"
              fontSize="md"
              placeholder="Total a pagar"
              variant="auth"
              size="lg"
            />
            <InputRightAddon color={textColor}>MXN</InputRightAddon>
          </InputGroup>
        </Flex>
      </SimpleGrid>
    </>
  );
}
