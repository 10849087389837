import axios from 'axios';
const API_BASE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

// Create Axios instance with base configuration
export const apiClient = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => status >= 200 && status < 500, // Resolve only if status is 2xx or 4xx
});

// Helper function for auth headers
export const getAuthHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// Centralized error handling
export const handleServiceError = (error) => {
  if (error.response) {
    // Server responded with non-2xx status
    console.error('API Error:', error.response.data);
    throw new Error(error.response.data.message || 'API request failed');
  } else if (error.request) {
    // No response received
    console.error('Network Error:', error.message);
    throw new Error('Network error - please check your connection');
  } else {
    // Other errors
    console.error('Request Error:', error.message);
    throw new Error('Error making request');
  }
};
