// Chakra imports
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// React
import { useState } from 'react';

// Custom components
import Card from 'components/card/Card.js';

// MUI imports
import {
  createTheme,
  ListItemText,
  MenuItem,
  Checkbox as MUICheckbox,
  Select,
  ThemeProvider,
} from '@mui/material';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { MdCenterFocusWeak } from 'react-icons/md';
import { displayNumericString, extractNumericValue } from 'utils/format';

export default function Descarga({
  entradas,
  secado,
  setSecado,
  isSubmitted,
  handleFocus,
  setSecadoEntries,
  latestId,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const theme = createTheme({});

  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectedEntradas, setSelectedEntradas] = useState([]);
  const [selectedEntradasIds, setSelectedEntradasIds] = useState([]);

  const uniqueEntradas = entradas
    .filter((x) => x.registerId.includes('.0'))
    .sort((a, b) => b.entradaId - a.entradaId)
    .map((x) => x.entradaId);

  const sumTotalBySpecificId = (specificId) => {
    const total = entradas
      .filter(
        (obj) =>
          +obj.entradaId === +specificId && !obj.registerId.includes('.0'),
      )
      .reduce(
        (accumulator, currentObject) => accumulator + +currentObject.pesoNeto,
        0,
      );

    return total;
  };

  const handleFolioWeights = (entradaId, totalWeight, totalFoliosSecado) => {
    setSecadoEntries(() => {
      let remainingWeight = totalWeight;

      const newSecadoEntries = Array.from(
        { length: totalFoliosSecado },
        (_, index) => {
          let folioWeight;
          if (index === totalFoliosSecado - 1) {
            folioWeight = remainingWeight;
          } else {
            folioWeight = Math.min(remainingWeight, 1000);
            remainingWeight -= folioWeight;
          }

          return {
            id: index + 1,
            folio: latestId + index + 1,
            claveDescarga: 'P-' + entradaId + '-' + (latestId + index + 1),
            peso: folioWeight.toFixed(2).toString(),
            pergamino: folioWeight.toFixed(2).toString(),
            pergaminera: '0.00',
            merma: '0.00',
          };
        },
      );

      return newSecadoEntries;
    });

    setSecado((prevState) => ({
      ...prevState,
      pergaminoTotal: totalWeight,
      pergamineraTotal: '0.00',
      mermaTotal: '0.00',
    }));
  };

  const handleSelectedEntries = (e, entry) => {
    const isChecked = e.target.checked;
    const excluidos = isChecked
      ? secado.excluidos.filter((id) => id !== entry.registerId)
      : [...secado.excluidos, entry.registerId];

    const totalWeight = isChecked
      ? (parseFloat(secado.pesoTotal) || 0) + (parseFloat(entry.pesoNeto) || 0)
      : (parseFloat(secado.pesoTotal) || 0) - (parseFloat(entry.pesoNeto) || 0);

    const totalFoliosSecado = Math.ceil(totalWeight / 1000);

    setSecado((prevState) => ({
      ...prevState,
      excluidos: excluidos,
      pesoTotal: totalWeight,
      foliosTotal: totalFoliosSecado,
      pergaminoTotal: '',
      pergamineraTotal: '',
      mermaTotal: '',
    }));

    const folioIds = secado.entradaId.join(',');

    handleFolioWeights(folioIds, totalWeight, totalFoliosSecado);
  };

  const handleEntradaSelection = (
    newSelectedEntradas = [],
    totalWeightInput = 0,
    totalFoliosSecadoInput = 0,
  ) => {
    const hasNewSelectedEntradas = newSelectedEntradas?.length > 0;

    const entradaIds = hasNewSelectedEntradas
      ? [...new Set(newSelectedEntradas.map((x) => x.entradaId))]
      : [...new Set(secado.entradaId)];

    const selectedGeneralEntries = hasNewSelectedEntradas
      ? newSelectedEntradas.filter((obj) => obj.registerId.includes('.0'))
      : selectedEntradas.filter((obj) => obj.registerId.includes('.0'));

    const selectedSubentries = hasNewSelectedEntradas
      ? newSelectedEntradas.filter((obj) => !obj.registerId.includes('.0'))
      : selectedEntradas.filter((obj) => !obj.registerId.includes('.0'));

    const totalWeight = hasNewSelectedEntradas
      ? entradaIds.reduce((acc, id) => acc + sumTotalBySpecificId(id), 0)
      : totalWeightInput || secado.pesoTotal;

    const totalFoliosSecado = hasNewSelectedEntradas
      ? Math.ceil(totalWeight / 1000)
      : totalFoliosSecadoInput ||
        (totalWeightInput ? secado.foliosTotal : null);

    setSecado((prevState) => ({
      ...prevState,
      pesoTotal: totalWeight,
      foliosTotal: totalFoliosSecado,
      entradaId: entradaIds,
      cosecha:
        selectedGeneralEntries[0]?.cosecha ||
        selectedSubentries[0]?.cosecha ||
        prevState.cosecha,
      productor: selectedGeneralEntries[0]?.productor || prevState.productor,
      estado: selectedGeneralEntries[0]?.estado || prevState.estado,
      region: selectedGeneralEntries[0]?.region || prevState.region,
      municipio: selectedGeneralEntries[0]?.municipio || prevState.municipio,
      comunidad: selectedGeneralEntries[0]?.comunidad || prevState.comunidad,
      tipo: selectedGeneralEntries[0]?.tipo || prevState.tipo,
      caracteristica:
        selectedGeneralEntries[0]?.caracteristica ||
        selectedSubentries[0]?.caracteristica ||
        prevState.caracteristica,
    }));
    setSelectedEntries(selectedSubentries);

    const folioIds = hasNewSelectedEntradas
      ? entradaIds.join(',')
      : secado.entradaId.join(',');

    handleFolioWeights(folioIds, totalWeight, totalFoliosSecado);
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdCenterFocusWeak color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Selección de entrada
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '3' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Entrada ID
              </FormLabel>
            </Flex>
            <ThemeProvider theme={theme}>
              <Select
                multiple
                sx={{ height: '40px', borderRadius: ' 8px' }}
                value={selectedEntradasIds}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      // Placeholder styling
                      <span
                        style={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          fontFamily: 'inherit',
                        }}
                      >
                        Entrada ID
                      </span>
                    );
                  }
                  // Sort numerically and join
                  return [...selected].sort((a, b) => a - b).join(', ');
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValues = value.map(Number);
                  const newSelectedEntradas = entradas.filter((x) =>
                    numericValues.includes(x.entradaId),
                  );
                  setSelectedEntradasIds(numericValues);
                  setSelectedEntradas(newSelectedEntradas);
                  handleEntradaSelection(newSelectedEntradas);
                }}
                onFocus={handleFocus}
                variant="outlined"
                placeholder="Entrada ID"
              >
                {uniqueEntradas.map((x) => (
                  <MenuItem key={x} value={x}>
                    <MUICheckbox checked={selectedEntradasIds.includes(x)} />
                    <ListItemText primary={x} />
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Kilogramos totales
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !secado.pesoTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                onChange={(e) => {
                  const value = extractNumericValue(e.target.value);
                  handleEntradaSelection(false, value, 0);
                }}
                value={displayNumericString(secado.pesoTotal)}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Kilogramos totales"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Folios de secado generados
            </FormLabel>
            <InputGroup>
              <Input
                borderColor={
                  isSubmitted && !secado.foliosTotal && !secado.entradaId
                    ? 'red.500'
                    : null
                }
                onChange={(e) => {
                  handleEntradaSelection(false, 0, +e.target.value.trim());
                }}
                value={secado.foliosTotal}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Folios de secado"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Productor
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.productor ? 'red.500' : null
                }
                value={secado.productor}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Productor"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Región
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={isSubmitted && !secado.region ? 'red.500' : null}
                value={secado.region}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Región"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Municipio
            </FormLabel>
            <InputGroup>
              <Input
                disabled
                borderColor={
                  isSubmitted && !secado.municipio ? 'red.500' : null
                }
                value={secado.municipio}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Municipio"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mt={'1rem'}
        >
          {secado.entradaId && (
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Subregistros seleccionados
              </FormLabel>
              <Stack spacing={5} direction="row">
                {selectedEntries?.map((entry) => (
                  <Checkbox
                    key={entry.registerId}
                    onChange={(e) => handleSelectedEntries(e, entry)}
                    isChecked={!secado.excluidos.includes(entry.registerId)}
                    color={textColor}
                  >
                    {entry.registerId} {`[${entry.pesoNeto} KG]`}
                  </Checkbox>
                ))}
              </Stack>
            </Flex>
          )}
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
