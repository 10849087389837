import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import Dropdown from 'views/admin/seco/catacion/modals/components/Dropdown';
import Step from 'views/admin/seco/catacion/modals/components/Step';
import { fields } from 'views/admin/seco/catacion/modals/constants/variables';

export default function ScoreModal({ isOpen, onClose, catacion, setCatacion }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={'800px'}>
        <ModalHeader
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          mt="20px"
          pb="0px"
          textAlign={'center'}
        >
          Calificaciones del perfil
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={10}>
          <Flex
            mb={10}
            justifyContent="center"
            alignItems="center"
            w="50%"
            mx="auto"
          >
            <Text
              color="secondaryGray.600"
              fontSize="md"
              fontWeight="500"
              textAlign="center"
            >
              Favor de seleccionar los siguientes puntajes
            </Text>
          </Flex>
          <FormControl>
            <SimpleGrid
              columns={{ base: 3, md: 3, lg: 3 }}
              gap={{ sm: '10px', md: '26px' }}
              mb={'2rem'}
            >
              {fields.map((field) => (
                <React.Fragment key={field.value}>
                  {field.type === 'step' && (
                    <Step
                      catacion={catacion}
                      setCatacion={setCatacion}
                      field={field}
                    />
                  )}
                  {field.type === 'dropdown' && (
                    <Dropdown
                      catacion={catacion}
                      setCatacion={setCatacion}
                      field={field}
                    />
                  )}
                </React.Fragment>
              ))}
            </SimpleGrid>
          </FormControl>
          <Flex
            mt="3.5rem"
            justifyContent="center"
            gap="20px"
            alignSelf="center"
            mx="auto"
          >
            <Button
              variant={'brand'}
              _hover={{ opacity: 0.8, transform: 'scale(1.1)' }}
              onClick={onClose}
            >
              Registrar
            </Button>
            <Button
              variant={'lightBrand'}
              _hover={{ opacity: 0.8, transform: 'scale(1.1)' }}
              onClick={onClose}
            >
              Volver
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
