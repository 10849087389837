import { Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import FlavorItem from 'components/dataDisplay/FlavorItem';
import { GiCoffeeBeans } from 'react-icons/gi';

export default function Aromas({
  selectedFlavors,
  setSelectedFlavors,
  isLoading,
  handleSubmit,
  setCommunityModal,
  perfilsByCommunity,
}) {
  const iconColor = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const buttonBgColor = useColorModeValue('gray.200', 'gray.600');
  const buttonActiveBgColor = useColorModeValue('gray.400', 'gray.800');
  const buttonColor = useColorModeValue('black', 'white');

  const handlePerfilRendering = (type, item) => {
    let flavor = item ? item : selectedFlavors[selectedFlavors.length - 1];

    switch (type) {
      case 'name': {
        return flavor?.nombre ? flavor.nombre : 'Nombre del perfil';
      }
      case 'hexcode': {
        return flavor?.hexcode ? flavor.hexcode : '#000';
      }
      case 'group': {
        const selectedFlavors = [
          flavor?.categoriaA,
          flavor?.categoriaB,
          flavor?.categoriaC,
          flavor?.categoriaD,
        ]
          .filter(Boolean)
          .join(', ');

        return selectedFlavors ? selectedFlavors : '-';
      }
      case 'type': {
        return flavor?.tipo ? flavor.tipo : '-';
      }
      default: {
        return '';
      }
    }
  };

  const handleSelectByCommunity = () => {
    setCommunityModal({
      state: true,
      title: 'Selecciona una comunidad',
      subtitle: 'Los perfiles de esta comunidad serán asignados.',
      communities: perfilsByCommunity,
    });
  };

  return (
    <Flex direction="column">
      <Text fontSize="xl" color={textColor} fontWeight="700" mb="30px">
        3 Aromas | Sabores identificados
      </Text>
      {selectedFlavors?.length > 0 && (
        <Flex justify="center" mb={'15px'}>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar perfil
          </Button>
        </Flex>
      )}
      {selectedFlavors?.length === 0 && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          height="100%"
        >
          <Icon as={GiCoffeeBeans} color={iconColor} w="40px" h="40px" />
          <Text
            fontSize="sm"
            color={textColor}
            fontWeight="500"
            textAlign="center"
          >
            No hay valores registrados aún.
          </Text>
          <Button
            onClick={() => handleSelectByCommunity()}
            bg={buttonBgColor}
            color={buttonColor}
            _hover={{ bg: buttonActiveBgColor }}
            borderRadius="6px"
            mb="34px"
          >
            Seleccionar perfil por comunidad
          </Button>
        </Flex>
      )}
      <Flex flexDirection="column" justify="flex-start">
        {selectedFlavors?.map((x) => {
          return (
            x.nombre &&
            x.hexcode && (
              <FlavorItem
                key={x.hexcode}
                mb="26px"
                name={x.nombre}
                specification={`${handlePerfilRendering(
                  'type',
                  x,
                )} | ${handlePerfilRendering('group', x)}`}
                selectedFlavors={selectedFlavors}
                setSelectedFlavors={setSelectedFlavors}
                icon={
                  <Icon
                    as={GiCoffeeBeans}
                    color={iconColor}
                    w="20px"
                    h="18px"
                  />
                }
              />
            )
          );
        })}
      </Flex>
    </Flex>
  );
}
