import {
  apiClient,
  getAuthHeaders,
  handleServiceError,
} from 'api/config/config';

// Helper functions
const sanitizeEntrada = (entrada) => {
  const toNumberWithTwoDecimals = (value) => parseFloat(value || 0).toFixed(2);

  return {
    entradaId: entrada.entradaId || '',
    fecha: new Date(),
    registerId: `${entrada.entradaId}.${entrada.id || 0}` || entrada.registerId,
    cosechaId: entrada.cosechaId || `${entrada.entradaId}.${entrada.id || 0}`,
    estado: entrada.estado || '',
    region: entrada.region || '',
    municipio: entrada.municipio || '',
    comunidad: entrada.comunidad || '',
    productor: entrada.productor || '',
    cosecha: entrada.cosecha || '',
    tipo: entrada.tipo || '',
    chofer: entrada.chofer || '',
    placas: entrada.placas || '',
    marca: entrada.marca || '',
    unidad: entrada.unidad || '',
    cumple: entrada.cumple || '',
    caracteristica: entrada.caracteristica || '',
    ticketBascula: entrada.ticketBascula || '',
    pesoConCarga: toNumberWithTwoDecimals(entrada.pesoConCarga),
    pesoSinCarga: toNumberWithTwoDecimals(entrada.pesoSinCarga),
    yutes: toNumberWithTwoDecimals(entrada.yutes),
    lonas: toNumberWithTwoDecimals(entrada.lonas),
    cajas: toNumberWithTwoDecimals(entrada.cajas),
    tara: toNumberWithTwoDecimals(entrada.tara),
    pesoBruto: toNumberWithTwoDecimals(entrada.pesoBruto),
    pesoNeto: toNumberWithTwoDecimals(entrada.pesoNeto),
    pesoPorSaco: toNumberWithTwoDecimals(entrada.pesoPorSaco),
    limiteHumedad: toNumberWithTwoDecimals(entrada.limiteHumedad),
    humedad: toNumberWithTwoDecimals(entrada.humedad),
    descuentoHumedad: toNumberWithTwoDecimals(entrada.descuentoHumedad),
    descuentoHumedadPeso: toNumberWithTwoDecimals(entrada.descuentoHumedadPeso),
    precioPrevio: toNumberWithTwoDecimals(entrada.precioPrevio),
    precioKg: toNumberWithTwoDecimals(entrada.precioKg),
    precioTotal: toNumberWithTwoDecimals(entrada.precioTotal),
    observaciones: entrada.observaciones || '',
    createdAt: new Date(),
  };
};

// Entrada Services
export const entradaService = {
  getAllEntradas: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/entrada/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true, isDeleted: false },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  getLatestId: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/entrada/list`,
        {
          options: {
            paginate: 1,
            sort: { id: -1 },
            select: ['entradaId'],
            where: { isActive: true, isDeleted: false },
          },
          isCountOnly: false,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addEntrada: async (token, userId, entrada) => {
    try {
      const sanitizedEntrada = sanitizeEntrada(entrada, userId);
      return await apiClient.post(
        'client/api/v1/entrada/create',
        sanitizedEntrada,
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addAllEntradas: async (token, entradas, editMode) => {
    try {
      const sanitizedEntradas = await entradas.map((entrada) =>
        sanitizeEntrada(entrada),
      );
      return await apiClient.post(
        'client/api/v1/entrada/addBulk',
        {
          editMode,
          data: sanitizedEntradas,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
};
