import { Icon } from '@chakra-ui/react';
import { GiCoffeeBeans } from 'react-icons/gi';
import {
  MdLock,
  MdOutlineFolderCopy,
  MdOutlineWaterDrop,
} from 'react-icons/md';
import {
  PiChartScatter,
  PiDisc,
  PiFlask,
  PiGooglePhotosLogo,
  PiTruckLight,
} from 'react-icons/pi';

// Beneficio húmedo
import HumedoEntradaBaseDatos from 'views/admin/humedo/entradas/BaseDatos.jsx';
import HumedoEntradaRegistro from 'views/admin/humedo/entradas/Registro.jsx';
import HumedoFermentacionBaseDatos from 'views/admin/humedo/fermentacion/BaseDatos.jsx';
import HumedoFermentacionRegistro from 'views/admin/humedo/fermentacion/Registro.jsx';

// Beneficio seco
import CatacionBaseDatos from 'views/admin/seco/catacion/BaseDatos.jsx';
import CatacionComunidades from 'views/admin/seco/catacion/Comunidades';
import CatacionFolios from 'views/admin/seco/catacion/Folios';
import CatacionMuestras from 'views/admin/seco/catacion/Muestras';
import EntradaBaseDatos from 'views/admin/seco/entradas/BaseDatos.jsx';
import EntradaRegistro from 'views/admin/seco/entradas/Registro.jsx';
import MaquilaBaseDatos from 'views/admin/seco/maquila/BaseDatos.jsx';
import MaquilaOrdenFolio from 'views/admin/seco/maquila/OrdenFolio.jsx';
import MaquilaOrdenPerfil from 'views/admin/seco/maquila/OrdenPerfil.jsx';
import RendimientoBaseDatos from 'views/admin/seco/rendimiento/BaseDatos.jsx';
import RendimientoRegistro from 'views/admin/seco/rendimiento/Registro.jsx';
import SalidasBaseDatos from 'views/admin/seco/salidas/BaseDatos.jsx';
import SalidasRegistro from 'views/admin/seco/salidas/Registro.jsx';
import SecadoBaseDatos from 'views/admin/seco/secado/BaseDatos.jsx';
import SecadoRegistro from 'views/admin/seco/secado/Registro.jsx';

// Inventarios
import InventarioFolios from 'views/admin/inventarios/folios';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';

const routes = [
  // --- Inventarios --- //
  {
    name: 'Inventarios',
    path: '/inventario',
    icon: (
      <Icon
        as={MdOutlineFolderCopy}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Panel de control',
        layout: '/admin',
        path: '/inventarios/panel-de-control',
        component: <InventarioFolios />,
      },
    ],
  },
  // --- Beneficio húmedo --- //
  {
    name: 'Húmedo',
    path: '/humedo',
    icon: (
      <Icon
        as={MdOutlineWaterDrop}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Entradas',
        path: '/humedo/entradas',
        icon: (
          <Icon as={PiTruckLight} width="15px" height="15px" color="inherit" />
        ),
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/humedo/entradas/registro',
            component: <HumedoEntradaRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/humedo/entradas/base-de-datos',
            component: <HumedoEntradaBaseDatos />,
          },
        ],
      },
      {
        name: 'Fermentación',
        path: '/humedo/fermentacion',
        icon: <Icon as={PiFlask} width="15px" height="15px" color="inherit" />,
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/humedo/fermentacion/registro',
            component: <HumedoFermentacionRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/humedo/fermentacion/base-de-datos',
            component: <HumedoFermentacionBaseDatos />,
          },
        ],
      },
    ],
  },
  // --- Beneficio Seco --- //
  {
    name: 'Seco',
    path: '/seco',
    icon: (
      <Icon as={GiCoffeeBeans} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: 'Entradas',
        path: '/seco/entradas',
        icon: (
          <Icon as={PiTruckLight} width="15px" height="15px" color="inherit" />
        ),
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/seco/entradas/registro',
            component: <EntradaRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/entradas/base-de-datos',
            component: <EntradaBaseDatos />,
          },
        ],
      },
      {
        name: 'Secado',
        path: '/seco/secado',
        icon: (
          <Icon
            as={PiGooglePhotosLogo}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/seco/secado/registro',
            component: <SecadoRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/secado/base-de-datos',
            component: <SecadoBaseDatos />,
          },
        ],
      },
      {
        name: 'Rendimiento',
        path: '/seco/rendimiento',
        icon: (
          <Icon
            as={PiChartScatter}
            width="15px"
            height="15px"
            color="inherit"
          />
        ),
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/seco/rendimiento/registro',
            component: <RendimientoRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/rendimiento/base-de-datos',
            component: <RendimientoBaseDatos />,
          },
        ],
      },
      {
        name: 'Catación',
        path: '/seco/catacion',
        icon: <Icon as={PiDisc} width="15px" height="15px" color="inherit" />,
        collapse: true,
        items: [
          {
            name: 'Folios',
            layout: '/admin',
            path: '/seco/catacion/perfiles',
            component: <CatacionFolios />,
          },
          {
            name: 'Muestras',
            layout: '/admin',
            path: '/seco/catacion/muestras',
            component: <CatacionMuestras />,
          },
          {
            name: 'Comunidades',
            layout: '/admin',
            path: '/seco/catacion/comunidades',
            component: <CatacionComunidades />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/catacion/base-de-datos',
            component: <CatacionBaseDatos />,
          },
        ],
      },
      {
        name: 'Maquila',
        path: '/seco/maquila',
        icon: (
          <Icon as={GiCoffeeBeans} width="15px" height="15px" color="inherit" />
        ),
        collapse: true,
        items: [
          {
            name: 'Orden por folio',
            layout: '/admin',
            path: '/seco/maquila/orden-folio',
            component: <MaquilaOrdenFolio />,
          },
          {
            name: 'Orden por perfil',
            layout: '/admin',
            path: '/seco/maquila/orden-perfil',
            component: <MaquilaOrdenPerfil />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/maquila/base-de-datos',
            component: <MaquilaBaseDatos />,
          },
        ],
      },
      {
        name: 'Salidas',
        path: '/seco/salidas',
        icon: (
          <Icon as={PiTruckLight} width="15px" height="15px" color="inherit" />
        ),
        collapse: true,
        items: [
          {
            name: 'Registro',
            layout: '/admin',
            path: '/seco/salidas/registro',
            component: <SalidasRegistro />,
          },
          {
            name: 'Base de datos',
            layout: '/admin',
            path: '/seco/salidas/base-de-datos',
            component: <SalidasBaseDatos />,
          },
        ],
      },
    ],
  },
  // --- Autenticación --- //
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-in/centered',
            component: <SignInCentered />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-up/centered',
            component: <SignUpCentered />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
];

export default routes;
