import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Step({ field, catacion, setCatacion }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('secondaryGray.200', 'white');

  return (
    <Flex direction="column" key={field.value}>
      <FormLabel
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        mb="8px"
      >
        {field.label}
        {field.type === 'step' && (
          <Flex gap="15px">
            <Text fontSize="12px" color="secondaryGray.600">
              Min: {field.min || 6}
            </Text>
            <Text fontSize="12px" color="secondaryGray.600">
              Max: {field.max || 10}
            </Text>
          </Flex>
        )}
      </FormLabel>
      <InputGroup>
        <Input
          type="number"
          step={field.step || '0.25'}
          borderColor={borderColor}
          value={catacion.calificaciones?.[field.value] ?? field.min}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            setCatacion((prev) => ({
              ...prev,
              calificaciones: {
                ...prev.calificaciones,
                [field.value]: newValue,
              },
            }));
          }}
          onBlur={(e) => {
            let value = parseFloat(e.target.value) || field.min || 6;
            value = Math.min(Math.max(value, field.min || 6), field.max || 10);
            value = Math.round(value * 4) / 4;
            setCatacion((prev) => ({
              ...prev,
              calificaciones: {
                ...prev.calificaciones,
                [field.value]: value,
              },
            }));
          }}
          borderRadius="6px"
          height="40px"
          fontSize="md"
          placeholder={field.label}
          variant="auth"
          size="lg"
          _focus={{
            borderWidth: '2px',
            borderColor: textColor,
            boxShadow: 'none',
          }}
        />
        <Flex direction="column" alignItems="center" ml="10px">
          <IconButton
            icon={<ChevronUpIcon />}
            size="xs"
            aria-label={`Incrementar ${catacion.calificaciones?.[field.value]}`}
            onClick={() => {
              const newValue =
                (parseFloat(catacion.calificaciones?.[field.value]) ||
                  field.min ||
                  6) + 0.25;
              setCatacion((prev) => ({
                ...prev,
                calificaciones: {
                  ...prev.calificaciones,
                  [field.value]: Math.min(newValue, field.max || 10),
                },
              }));
            }}
          />
          <IconButton
            icon={<ChevronDownIcon />}
            size="xs"
            aria-label={`Decrementar ${catacion.calificaciones?.[field.value]}`}
            onClick={() => {
              const newValue =
                (parseFloat(catacion.calificaciones?.[field.value]) ||
                  field.max ||
                  10) - 0.25;
              setCatacion((prev) => ({
                ...prev,
                calificaciones: {
                  ...prev.calificaciones,
                  [field.value]: Math.max(newValue, field.min || 6),
                },
              }));
            }}
          />
        </Flex>
      </InputGroup>
    </Flex>
  );
}
