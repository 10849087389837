import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import { QuestionIcon } from '@chakra-ui/icons';
import { CATACION } from 'constant/constant';

export default function Folio({
  catacion,
  setCatacion,
  secado,
  handleFocus,
  isSubmitted,
}) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const handleFolioSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setCatacion((prevState) => ({
      ...prevState,
      folioSecado: e.target.value.trim(),
      entradaId: selectedOption?.dataset?.entrada || prevState.entrada,
      productor: selectedOption?.dataset?.productor || prevState.productor,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
      tipo: selectedOption?.dataset?.tipo || prevState.tipo,
      caracteristica:
        selectedOption?.dataset?.caracteristica || prevState.caracteristica,
      claveDescarga:
        selectedOption?.dataset?.descarga || prevState.claveDescarga,
    }));
  };

  return (
    <FormControl>
      <Flex direction="column">
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="700"
          mt="30px"
          mb="15px"
        >
          1 Folio
        </Text>
        <Flex alignItems={'center'}>
          <Tooltip label={CATACION.FOLIO_TOOLTIP} aria-label="A tooltip">
            <FormLabel
              display="flex"
              alignItems="center"
              ms="4px"
              fontSize="16px"
              fontWeight="500"
              color={textColor}
              mb="8px"
              gap="10px"
            >
              Folio de secado <QuestionIcon color={textColor} />
            </FormLabel>
          </Tooltip>
        </Flex>
        <Select
          borderColor={isSubmitted && !catacion.folioSecado ? 'red.500' : null}
          value={catacion.folioSecado || ''}
          onChange={(e) => {
            handleFolioSelection(e);
          }}
          onFocus={handleFocus}
          variant="auth"
          placeholder="Folio de secado"
        >
          {secado.length > 0 &&
            secado.map((x) => (
              <option
                key={x.id}
                value={x.id}
                data-productor={x.productor || 'No especificado'}
                data-estado={x.estado || 'No especificado'}
                data-region={x.region || 'No especificado'}
                data-municipality={x.municipio || 'No especificado'}
                data-community={x.comunidad || 'No especificado'}
                data-tipo={x.tipo || 'No especificado'}
                data-caracteristica={x.caracteristica || 'No especificado'}
                data-descarga={x.claveDescarga || ''}
                data-entrada={x.entradaId || ''}
              >
                {x.id}
              </option>
            ))}
        </Select>
      </Flex>
    </FormControl>
  );
}
