import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Comunidad({
  catacion,
  setCatacion,
  origen,
  handleFocus,
  isSubmitted,
}) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const handleComunidadSelection = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setCatacion((prevState) => ({
      ...prevState,
      folioSecado: 0,
      entradaId: 0,
      estado: selectedOption?.dataset?.estado || prevState.estado,
      region: selectedOption?.dataset?.region || prevState.region,
      municipio: selectedOption?.dataset?.municipality || prevState.municipio,
      comunidad: selectedOption?.dataset?.community || prevState.comunidad,
    }));
  };

  const handleClient = (e) => {
    setCatacion((prevState) => ({
      ...prevState,
      cliente: e.target.value,
    }));
  };

  return (
    <Flex direction="column">
      <FormControl>
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="700"
          mt="30px"
          mb="15px"
        >
          1 Cliente
        </Text>
        <Flex direction="column" mb="15px">
          <FormLabel
            display="flex"
            alignItems="center"
            ms="4px"
            fontSize="16px"
            fontWeight="500"
            color={textColor}
            mb="8px"
            gap="10px"
          >
            Cliente
          </FormLabel>
          <Input
            id="client"
            borderColor={isSubmitted && !catacion.cliente ? 'red.500' : null}
            value={catacion.cliente}
            onChange={(e) => handleClient(e)}
            onFocus={handleFocus}
            borderRadius="6px"
            height="40px"
            isRequired={true}
            fontSize="md"
            placeholder="Cliente"
            variant="auth"
            size="lg"
          />
        </Flex>
        <Flex direction="column">
          <FormLabel
            display="flex"
            alignItems="center"
            ms="4px"
            fontSize="16px"
            fontWeight="500"
            color={textColor}
            mb="8px"
            gap="10px"
          >
            Comunidad
          </FormLabel>
          <Select
            borderColor={isSubmitted && !catacion.comunidad ? 'red.500' : null}
            value={catacion.comunidad || ''}
            onChange={(e) => {
              handleComunidadSelection(e);
            }}
            onFocus={handleFocus}
            variant="auth"
            placeholder="Comunidad"
          >
            {origen.length > 0 &&
              origen
                .filter((x) => x.comunidad)
                .map((x) => (
                  <option
                    key={x.comunidad}
                    value={x.comunidad}
                    data-estado={x.estado || 'No especificado'}
                    data-region={x.region || 'No especificado'}
                    data-municipality={x.municipio || 'No especificado'}
                    data-community={x.comunidad || 'No especificado'}
                  >
                    {x.comunidad}
                  </option>
                ))}
          </Select>
        </Flex>
      </FormControl>
    </Flex>
  );
}
