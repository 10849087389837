export const fields = [
  {
    label: 'Fragancia/Aroma',
    value: 'fragancia',
    type: 'step',
    max: 10,
    min: 6,
  },
  { label: 'Sabor', value: 'sabor', type: 'step', max: 10, min: 6 },
  {
    label: 'Sabor Residual',
    value: 'saborResidual',
    type: 'step',
    max: 10,
    min: 6,
  },
  { label: 'Ácidez', value: 'acidez', type: 'step', max: 10, min: 6 },
  {
    label: 'Intensidad (ácidez)',
    value: 'intensidad',
    type: 'dropdown',
    options: ['Alta', 'Media alta', 'Media', 'Media baja', 'Baja'],
  },
  { label: 'Cuerpo', value: 'cuerpo', type: 'step', max: 10, min: 6 },
  {
    label: 'Nivel (Cuerpo)',
    value: 'nivel',
    type: 'dropdown',
    options: ['Pesado', 'Medio pesado', 'Medio', 'Medio ligero', 'Ligero'],
  },
  {
    label: 'Uniformidad',
    value: 'uniformidad',
    type: 'dropdown',
    options: [
      'Uniforme',
      'Casi uniforme',
      'Parcialmente uniforme',
      'Poco uniforme',
      'No uniforme',
    ],
  },
  { label: 'Balance', value: 'balance', type: 'step', max: 10, min: 6 },
  {
    label: 'Taza',
    value: 'taza',
    type: 'dropdown',
    options: [
      'Limpia',
      'Casi limpia',
      'Parcialmente limpia',
      'Poco limpia',
      'Sucia',
    ],
  },
  {
    label: 'Dulzor',
    value: 'dulzor',
    type: 'dropdown',
    options: [
      'Dulce',
      'Casi dulce',
      'Parcialmente dulce',
      'Poco dulce',
      'Amargo',
    ],
  },
];
