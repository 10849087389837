import {
  apiClient,
  getAuthHeaders,
  handleServiceError,
} from 'api/config/config';

// Cafe Services
export const cafeService = {
  getAllTipo: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/tipo/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  getAllCaracteristica: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/caracteristica/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  getAllPerfil: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/perfil/list`,
        {
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
            where: { isActive: true },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  getAllMicroorganismo: async (token) => {
    try {
      return await apiClient.post(
        `client/api/v1/microorganismo/list`,
        {
          options: {
            paginate: 500,
            sort: { clave: 1 },
            where: { isActive: true },
          },
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
  addMicroorganismo: async (token, userId, microorganismo) => {
    try {
      return await apiClient.post(
        'client/api/v1/microorganismo/create',
        {
          clave: microorganismo.clave,
          nombre: microorganismo.nombre,
          tipo: microorganismo.tipo,
          origen: microorganismo.origen,
          responsable: microorganismo.responsable,
          notas: microorganismo.notas,
          createdBy: userId,
        },
        getAuthHeaders(token),
      );
    } catch (error) {
      handleServiceError(error);
    }
  },
};
