export const addOrdenMaquila = async (TOKEN, USERID, ordenMaquila) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/maquila/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          fecha: ordenMaquila.fecha || new Date(),
          entradaId: ordenMaquila.entradaId,
          folioSecado: ordenMaquila.folioSecado,
          estado: ordenMaquila.estado,
          region: ordenMaquila.region,
          municipio: ordenMaquila.municipio,
          comunidad: ordenMaquila.comunidad,
          productor: ordenMaquila.productor,
          cosecha: ordenMaquila.cosecha,
          tipo: ordenMaquila.tipo,
          caracteristica: ordenMaquila.caracteristica,
          claveDescarga: ordenMaquila.claveDescarga,
          purgaTren: ordenMaquila.purgaTren,
          liberacionMateriaPrima: ordenMaquila.liberacionMateriaPrima,
          maquilaTipo: ordenMaquila.maquilaTipo,
          numeroIco: ordenMaquila.numeroIco,
          preparacion: ordenMaquila.preparacion,
          cliente: ordenMaquila.cliente,
          contrato: ordenMaquila.contrato,
          lote: ordenMaquila.lote,
          kilogramos: ordenMaquila.kilogramos,
          rendimientoExportable: ordenMaquila.rendimientoExportable,
          rendimientoTotal: ordenMaquila.rendimientoTotal,
          observaciones: ordenMaquila.observaciones,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllOrdenMaquila = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/maquila/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
