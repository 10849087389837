import { Button, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { addSecado, getLatestId } from 'api/secado';
import { entradaService } from 'api/services/entradaService';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { useEffect, useState } from 'react';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { ALERT } from '../../../../constant/constant';
import Descarga from './components/Descarga';
import Secado from './components/Secado';

export default function SecadoRegistro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const initialSecadoState = {
    entradaId: '',
    excluidos: [],
    pesoTotal: '',
    foliosTotal: '',
    cosecha: '',
    estado: '',
    region: '',
    comunidad: '',
    municipio: '',
    productor: '',
    tipo: '',
    caracteristica: '',
    pergaminoTotal: '',
    pergamineraTotal: '',
    mermaTotal: '',
    estatus: 'disponible',
    observaciones: '',
  };

  const initialSecadoEntriesState = [
    {
      id: 1,
      folio: '',
      claveDescarga: '',
      pergamino: '',
      pergaminera: '',
      merma: '',
    },
  ];

  const [entradas, setEntradas] = useState([]);
  const [secado, setSecado] = useState(initialSecadoState);
  const [secadoEntries, setSecadoEntries] = useState(initialSecadoEntriesState);
  const [latestId, setLatestId] = useState(1);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllEntradas = async () => {
      try {
        setIsLoading(true);
        const response = await entradaService.getAllEntradas(TOKEN);
        if (response.status === 200) {
          const resultData = result?.data?.data?.data || [];
          setEntradas(resultData);
        } else {
          handleMessage(
            'error',
            ALERT.ERROR_TITLE,
            ALERT.ERROR_SUBTITLE,
            false,
          );
        }
      } catch (error) {
        console.error(error);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, false);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchLatestId = async () => {
      const latestIdValue = await getLatestId(TOKEN);
      if (latestIdValue !== null && latestIdValue !== undefined) {
        setLatestId(latestIdValue);
      } else {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, false);
      }
    };

    fetchAllEntradas();
    fetchLatestId();
  }, []);

  const handleSecadoEntryChange = (field, value, id) => {
    setSecadoEntries((prevState) => {
      return prevState.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const handleChange = (field, value) => {
    setSecado((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every((value) => value !== null && value !== undefined);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const exceptions = ['observaciones'];
    const allSecadoPropertiesTruthy = handleAllTruthy(secado, exceptions);
    const allSecadoEntriesPropertiesTruthy = handleAllTruthy(
      secadoEntries,
      exceptions,
    );

    if (allSecadoPropertiesTruthy && allSecadoEntriesPropertiesTruthy) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        for (let entry of secadoEntries) {
          const submitSecado = { ...secado, ...entry };
          const response = await addSecado(TOKEN, USERID, submitSecado);
          const result = await response.json();

          if (response.status === 200 && result.status === 'SUCCESS') {
            setIsSubmitted(false);
            handleMessage(
              'success',
              ALERT.SUCCESS_TITLE,
              ALERT.SUCCESS_SUBTITLE,
            );
            setSecadoEntries(initialSecadoEntriesState);
            setSecado(initialSecadoState);

            window.scrollTo({ top: 0, left: 0 });
          } else {
            handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
          }
        }
        setLatestId((prevState) => prevState + secadoEntries.length);
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmitted(true);
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_FIELDS);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Descarga
              entradas={entradas}
              secado={secado}
              setSecado={setSecado}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              setSecadoEntries={setSecadoEntries}
              latestId={latestId}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Secado
              secado={secado}
              setSecado={setSecado}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
              secadoEntries={secadoEntries}
              handleSecadoEntryChange={handleSecadoEntryChange}
            />
          </Flex>
        </Grid>

        <Flex justify="center" my="40px">
          <Button
            onClick={handleSubmit}
            leftIcon={<MdOutlineDriveFileMove />}
            isLoading={isLoading}
            loadingText="Realizando registro..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Registrar secado
          </Button>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
