import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import dayjs from 'dayjs';
import { useState } from 'react';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import { extractNumericValue } from 'utils/format';
// MUI imports
import {
  ListItemText,
  MenuItem,
  Checkbox as MUICheckbox,
  Select,
} from '@mui/material';
import RegisterMicroorganismo from 'components/modal/register/RegisterMicroorganismo';
import { themeMUI } from 'utils/theme';

export default function FermentacionModule({
  title,
  icon,
  handleSubmit,
  handleChange,
  handleFocus,
  fields,
  fermentacion,
  loading,
}) {
  const modalButtonBg = useColorModeValue('brand.200', '#1B254B');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
  const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
  const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
  const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
  const theme = createTheme(
    themeMUI(mainColor, secondaryColor, textPrimaryColor, textSecondaryColor),
  );

  const [collapsed, setCollapsed] = useState(false);

  const toggleShow = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        minH={'80px'}
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <Icon
              as={icon || MdOutlineMonitorWeight}
              width="25px"
              height="25px"
              color="white"
            />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              {title || 'Módulo de fermentación'}
            </Text>
          </Flex>
          <Flex gap={'30px'} alignItems={'center'}>
            {fermentacion.entradaId && (
              <Button
                onClick={handleSubmit}
                isLoading={loading}
                loadingText={'Realizando registro...'}
                colorScheme="green"
              >
                Registrar
              </Button>
            )}
            <Tooltip
              hasArrow
              label={collapsed ? 'Contraer' : 'Expandir'}
              bg="gray.300"
              color="black"
            >
              <Button onClick={toggleShow} w={'56px'}>
                {collapsed ? (
                  <ChevronDownIcon fontSize="1.2rem" />
                ) : (
                  <ChevronUpIcon fontSize="1.2rem" />
                )}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      {collapsed && fields?.length > 0 && (
        <>
          <SimpleGrid
            columns={{ base: '1', md: '2', lg: '2' }}
            gap={{ sm: '10px', md: '26px' }}
            mb={'1rem'}
            px={'20px'}
          >
            {fields.map((x) => (
              <Flex direction={'column'} key={x.value}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  {x.label}{' '}
                  {x.addMicroorganismos && (
                    <RegisterMicroorganismo bgButtonColor={modalButtonBg} />
                  )}
                </FormLabel>
                {x.type === 'date' && (
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={
                          fermentacion?.[x.category]?.[x.value]
                            ? dayjs(
                                fermentacion?.[x.category]?.[x.value],
                                'YYYY-MM-DD',
                              )
                            : dayjs()
                        }
                        onChange={(newValue) => {
                          handleChange(
                            x.category,
                            x.value,
                            newValue ? newValue.format('YYYY-MM-DD') : '',
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                )}
                {x.type === 'time' && (
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={
                          fermentacion?.[x.category]?.[x.value]
                            ? dayjs(
                                fermentacion[x.category][x.value],
                                'hh:mm A',
                              )
                            : dayjs()
                        }
                        onChange={(newValue) => {
                          handleChange(
                            x.category,
                            x.value,
                            newValue ? newValue.format('hh:mm A') : '',
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                )}
                {x.type === 'dropdown' && (
                  <ThemeProvider theme={theme}>
                    <Select
                      multiple={x.multiple}
                      sx={{ height: '40px', borderRadius: '8px' }}
                      value={fermentacion?.[x.category]?.[x.value] || []}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span
                              style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontFamily: 'inherit',
                              }}
                            >
                              {x.label}
                            </span>
                          );
                        }
                        return x.multiple ? [...selected].join(', ') : selected;
                      }}
                      onChange={(e) =>
                        handleChange(x.category, x.value, e.target.value)
                      }
                      onFocus={handleFocus}
                      variant="outlined"
                    >
                      {x.options.map((option) => (
                        <MenuItem key={option} value={option}>
                          <MUICheckbox
                            checked={
                              fermentacion?.[x.category]?.[x.value]?.includes(
                                option,
                              ) ?? false
                            }
                          />
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                )}
                {x.type === 'weight' && (
                  <InputGroup>
                    <Input
                      value={
                        fermentacion?.[x.category]?.[x.value] ||
                        fermentacion?.pesoTotal ||
                        ''
                      }
                      onChange={(e) =>
                        handleChange(
                          x.category,
                          x.value,
                          extractNumericValue(e.target.value),
                        )
                      }
                      onFocus={handleFocus}
                      borderRadius="6px"
                      height="40px"
                      isRequired={true}
                      fontSize="md"
                      placeholder={
                        fermentacion?.[x.category]?.[x.value] ||
                        fermentacion?.pesoTotal ||
                        x.label
                      }
                      variant="auth"
                      size="lg"
                    />
                    {x?.unit && (
                      <InputRightAddon color={textColor}>
                        {x.unit}
                      </InputRightAddon>
                    )}
                  </InputGroup>
                )}
                {(x.type === 'text' || x.type === 'number') && (
                  <InputGroup>
                    <Input
                      value={fermentacion?.[x.category]?.[x.value] || ''}
                      onChange={(e) =>
                        handleChange(
                          x.category,
                          x.value,
                          x.type === 'number'
                            ? extractNumericValue(e.target.value)
                            : e.target.value,
                        )
                      }
                      onFocus={handleFocus}
                      borderRadius="6px"
                      height="40px"
                      isRequired={true}
                      fontSize="md"
                      placeholder={
                        fermentacion?.[x.category]?.[x.value] || x.label
                      }
                      variant="auth"
                      size="lg"
                    />
                    {x?.unit && (
                      <InputRightAddon color={textColor}>
                        {x.unit}
                      </InputRightAddon>
                    )}
                  </InputGroup>
                )}
              </Flex>
            ))}
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: '1', md: '1', lg: '1' }}
            gap={{ sm: '10px', md: '26px' }}
            my={'1rem'}
            px={'20px'}
          >
            <Flex direction={'column'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Observaciones
              </FormLabel>
              <Textarea
                placeholder="Escribe aquí las observaciones del proceso."
                value={fermentacion?.[fields[0].category]?.observaciones || ''}
                onChange={(e) =>
                  handleChange(
                    fields[0].category,
                    'observaciones',
                    e.target.value,
                  )
                }
              />
            </Flex>
          </SimpleGrid>
        </>
      )}
    </Card>
  );
}
