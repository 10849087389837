const categories = [
  'proceso',
  'microorganismos',
  'despulpadora',
  'laboratorio',
  'humedad',
];

export const generateColumnTableData = (dataArray) => {
  const tableColumnsFermentacion = dataArray.flatMap((item) => {
    return categories.flatMap((category) => {
      if (!item[category]) return [];

      let parsed;
      try {
        parsed = JSON.parse(item[category]);
      } catch (error) {
        return [];
      }

      if (!parsed) return [];

      // Handle array case
      if (Array.isArray(parsed)) {
        if (parsed.length === 0) return [];

        return parsed.flatMap((arrayItem, index) => {
          if (typeof arrayItem !== 'object' || arrayItem === null) return [];

          return Object.keys(arrayItem)
            .filter((key) => !['id', 'onShow'].includes(key))
            .map((key) => ({
              headerName: `${capitalize(category)} - Registro ${
                index + 1
              } - ${capitalize(key)}`,
              field: `${category}.${index}.${key}`,
              width: 300,
              isArray: true,
              dataType: getDataType(key),
            }));
        });
      }
      // Handle object case
      else if (typeof parsed === 'object') {
        const keys = Object.keys(parsed);
        if (keys.length === 0) return [];

        return keys.map((key) => ({
          headerName: `${capitalize(category)} - ${capitalize(key)}`,
          field: `${category}.${key}`,
          width: 200,
          isArray: false,
          dataType: getDataType(key),
        }));
      }

      return [];
    });
  });

  // De-duplicate columns
  const uniqueColumns = Array.from(
    new Set(tableColumnsFermentacion.map(JSON.stringify)),
  ).map(JSON.parse);

  return [
    { field: 'registerId', headerName: 'Entrada ID', width: 150 },
    { field: 'createdAt', headerName: 'Fecha', dataType: 'date', width: 150 },
    ...uniqueColumns,
  ];

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function getDataType(type) {
    const formattedType = type?.toLowerCase();
    switch (formattedType) {
      case 'kilos':
      case 'kilosecos':
      case 'kilovanos':
        return 'kilograms';
      case 'cantidad':
        return 'grams';
      case 'fecha':
        return 'date';
      case 'hora':
        return 'time';
      case 'temperatura':
        return 'temp';
      case 'humedad':
        return 'percentage';
      default:
        return '';
    }
  }
};
