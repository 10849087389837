import {
  CircularProgress,
  Flex,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllCatacion } from 'api/catacion';
import { getAllSecado } from 'api/secado';
import { cafeService } from 'api/services/cafeService';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FlavorWheel from './components/FlavorWheel';
import SelectionsPanel from './components/SelectionsPanel';

export default function MaquilaOrdenPerfil() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const [flavors, setFlavors] = useState([]);
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [selectedFolios, setSelectedFolios] = useState([]);
  const [catacion, setCatacion] = useState([]);
  const [secado, setSecado] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllPerfil = async () => {
      try {
        setIsLoading(true);
        const response = await cafeService.getAllPerfil(TOKEN);
        if (response.status === 200) {
          const resultData = response?.data?.data?.data || [];
          setFlavors(resultData);
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAllCatacion = async () => {
      try {
        setIsLoading(true);
        const response = await getAllCatacion(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setCatacion(resultData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleMessage(
            'error',
            'Error en la operación.',
            'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
          );
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage(
          'error',
          'Error en la operación.',
          'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
        );
        console.error(error);
      }
    };
    const fetchAllSecado = async () => {
      try {
        setIsLoading(true);
        const response = await getAllSecado(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter(
              (x) => x.estatus !== 'salida' && x.estatus !== 'maquila',
            ) || [];
          setSecado(resultData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleMessage(
            'error',
            'Error en la operación.',
            'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
          );
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage(
          'error',
          'Error en la operación.',
          'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
        );
        console.error(error);
      }
    };

    fetchAllSecado();
    fetchAllPerfil();
    fetchAllCatacion();
  }, []);

  const handleRenderFlavorItem = async (backgroundColor) => {
    if (backgroundColor) {
      const selection = flavors.find((x) => x.hexcode === backgroundColor);
      if (selection?.nombre && selection?.hexcode) {
        setSelectedFlavors((prevState) => {
          const isAlreadySelected = prevState.some(
            (flavor) => flavor.hexcode === selection.hexcode,
          );
          if (!isAlreadySelected) {
            return [...prevState, selection];
          } else {
            handleMessage(
              'error',
              'Este perfil ya ha sido seleccionado.',
              'Favor de seleccionar otro.',
            );
          }
          return prevState;
        });
      }
    }
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleMessage = (state, msg, sub, folios) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
      folios: folios || [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedFlavorsIds = new Set();
    const flavorMap = new Map();
    const allFlavorsMap = new Map();

    // Map all flavors to their ids and names
    for (let flavor of flavors) {
      flavorMap.set(`${flavor.hexcode}-${flavor.nombre}`, {
        id: flavor.id,
        name: flavor.nombre,
      });
      allFlavorsMap.set(flavor.id.toString(), flavor.nombre); // Map all flavor ids to names
    }

    // Populate the set of selected flavor ids
    for (let selectedFlavor of selectedFlavors) {
      const key = `${selectedFlavor.hexcode}-${selectedFlavor.nombre}`;
      if (flavorMap.has(key)) {
        const flavorData = flavorMap.get(key);
        selectedFlavorsIds.add(flavorData.id.toString());
      }
    }

    // Filter and map the folios
    let folios = catacion
      .filter(
        (x) =>
          x.perfiles?.split(',').some((id) => selectedFlavorsIds.has(id)) &&
          x.folioSecado !== 0,
      )
      .map((folio) => {
        const allFlavors = folio.perfiles
          .split(',')
          .map((id) => allFlavorsMap.get(id) || 'Desconocido'); // Map all perfiles ids to names

        return {
          ...folio,
          flavorNames: allFlavors, // Attach all flavor names to flavorNames property
        };
      })
      .sort((a, b) => {
        const aMatches = a.perfiles
          .split(',')
          .filter((id) => selectedFlavorsIds.has(id)).length;
        const bMatches = b.perfiles
          .split(',')
          .filter((id) => selectedFlavorsIds.has(id)).length;
        return bMatches - aMatches;
      });

    setSelectedFolios(folios);

    if (folios.length > 0) {
      handleMessage(
        'success',
        'Operación exitosa.',
        'Estos fueron los folios obtenidos:',
        folios,
      );
    } else {
      handleMessage(
        'error',
        'No se encontraron folios de café.',
        'Favor de intentar con otro perfil de aromas y sabores o contactar a un agente de soporte.',
      );
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      maxHeight={'85vh'}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
          >
            {!isLoading && flavors?.length > 0 && (
              <FlavorWheel
                flavors={flavors}
                handleRenderFlavorItem={handleRenderFlavorItem}
              />
            )}
            {isLoading && (
              <Flex mb="5" justifyContent={'center'} alignItems={'center'}>
                <CircularProgress isIndeterminate color={textColorBrand} />
              </Flex>
            )}
          </Flex>
          <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            <SelectionsPanel
              selectedFlavors={selectedFlavors}
              setSelectedFlavors={setSelectedFlavors}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
