const microorganismosOptions = [
  'B01 - Fertilizante Gear 10 / GG',
  'B02 - Suero de leche / Gustavo',
  'B03 - Suero de leche / Arce casa',
  'B04 - Pulpa Deshidratada / Ozolotepec / Laboratorio',
  'B05 - Pulpa Deshidratada / Ozolotepec / Beneficio',
  'B06 - Kombucha / 28-12-24 / Cristina',
  'B07 - Santiago Ixcuintepec / 24-12-24 / Líquido acu',
  'B08 - Cereza / Santiago Ixcuintepec / 24-12-24 / Cris',
  'B09 - Pulpa congelada / Laboratorio GG / Ozolotepec',
  'B10 - Candelaria / Ivan / 06-01-25',
  'B11 - San Juan Guelache / Gustavo / 08-01-25',
  'B12 - Cereza / Ozolotepec / Cosecha 2025',
  'B13 - Peñas negras / Cereza / 20-01-25',
  'B14 - Búlgaros / Cristina / 20-01-25',
  'B15 - Vinagre de Manzana / Cristina / 20-01-25',
  'L01 - Levadura vino / Fermadi K / cosecha 128 / Cristina',
  'L02 - Fertilizante Gear 10 / GG',
  'L03 - Pulpa Deshidratada / Ozolotepec / Laboratorio 2023',
  'L04 - Pulpa Deshidratada / Ozolotepec / Beneficio 2024',
  'L05 - Kombucha / 28-12-24 / Cristina',
  'L06 - Santiago Ixcuintepec / 24-12-24 / Líquido acumulado / Cristina',
  'L07 - Cereza / Santiago Ixcuintepec / 24-12-24 / Cristina',
  'L08 - Pulpa congelada / Laboratorio GG / Ozolotepec',
  'L09 - Candelaria / Ivan / 06-01-25',
  'L10 - San Juan Guelache / Gustavo / 08-01-25',
  'L11 - Cereza / Ozolotepec / Cosecha 2025',
  'L12 - Peñas negras / Cereza / 20-01-25',
  'L13 - Búlgaros / Cristina / 20-01-25',
  'L14 - Vinagre de Manzana / Cristina / 20-01-25',
];

export const fieldsLaboratorio = (microorganismos) => {
  const microOptions =
    microorganismos?.length > 0
      ? microorganismos.map((x) => `${x.clave} - ${x.nombre}`)
      : microorganismosOptions;
  return [
    {
      category: 'laboratorio',
      label: 'Kilogramos',
      value: 'kilos',
      type: 'weight',
      unit: 'KG',
    },
    {
      category: 'laboratorio',
      label: 'Microorganismos',
      value: 'microorganismos',
      type: 'dropdown',
      multiple: true,
      options: microOptions,
      addMicroorganismos: true,
    },
    { category: 'laboratorio', label: 'Fecha', value: 'fecha', type: 'date' },
    { category: 'laboratorio', label: 'Hora', value: 'hora', type: 'time' },
  ];
};

export const fieldsMicroorganismos = (microorganismos) => {
  const microOptions =
    microorganismos?.length > 0
      ? microorganismos.map((x) => `${x.clave} - ${x.nombre}`)
      : microorganismosOptions;
  return [
    {
      category: 'microorganismos',
      label: 'Kilogramos',
      value: 'kilos',
      type: 'weight',
      unit: 'KG',
    },
    { category: 'microorganismos', label: 'Hora', value: 'hora', type: 'time' },
    {
      category: 'microorganismos',
      label: 'Reactor',
      value: 'reactor',
      type: 'text',
    },
    {
      category: 'microorganismos',
      label: 'Escala (1-7)',
      value: 'escala',
      type: 'dropdown',
      multiple: false,
      options: ['1', '2', '3', '4', '5', '6', '7'],
    },
    {
      category: 'microorganismos',
      label: 'Temperatura ambiente',
      value: 'temperatura',
      type: 'text',
      unit: '°C',
    },
    {
      category: 'microorganismos',
      label: 'Microorganismo',
      value: 'lote',
      type: 'dropdown',
      multiple: true,
      options: microOptions,
      addMicroorganismos: true,
    },
    {
      category: 'microorganismos',
      label: 'Cantidad añadida',
      value: 'cantidad',
      type: 'number',
      unit: 'GR',
    },
    { category: 'microorganismos', label: 'pH', value: 'pH', type: 'number' },
    {
      category: 'microorganismos',
      label: '°Brix',
      value: 'brix',
      type: 'number',
    },
  ];
};

export const fieldsProceso = [
  {
    category: 'proceso',
    label: 'Proceso',
    value: 'proceso',
    type: 'text',
  },
  {
    category: 'proceso',
    label: 'Responsable',
    value: 'responsable',
    type: 'text',
  },
  {
    category: 'proceso',
    label: 'Tipo de proceso',
    value: 'tipo',
    type: 'dropdown',
    multiple: false,
    options: ['Lavado', 'Natural'],
  },
];

export const fieldsDespulpadora = [
  {
    category: 'despulpadora',
    label: 'Kilogramos',
    value: 'kilos',
    type: 'weight',
    unit: 'KG',
  },
  { category: 'despulpadora', label: 'Hora', value: 'hora', type: 'time' },
  {
    category: 'despulpadora',
    label: 'Reactor',
    value: 'reactor',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Resultados',
    value: 'resultados',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Elaboro',
    value: 'elaboro',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Responsable',
    value: 'responsable',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Autorizo',
    value: 'autorizo',
    type: 'text',
  },
];

export const fieldsHumedad = [
  {
    category: 'humedad',
    label: 'Humedad',
    value: 'humedad',
    type: 'number',
    unit: '%',
  },
  {
    category: 'humedad',
    label: 'Kilogramos secos',
    value: 'kiloSecos',
    type: 'number',
    unit: 'KG',
  },
  {
    category: 'humedad',
    label: 'Kilogramos vanos',
    value: 'kiloVanos',
    type: 'number',
    unit: 'KG',
  },
];
