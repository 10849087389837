import {
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { extractNumericValue } from 'utils/format';

// MUI imports
import {
  ListItemText,
  MenuItem,
  Checkbox as MUICheckbox,
  Select,
} from '@mui/material';
import RegisterMicroorganismo from 'components/modal/register/RegisterMicroorganismo';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { IoRemoveCircleOutline } from 'react-icons/io5';

export default function MultipleForm({
  entry,
  handleChange,
  handleFocus,
  fields,
  category,
  deleteEntry,
  onShow,
  toggleShow,
}) {
  const modalButtonBg = useColorModeValue('brand.200', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
  const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
  const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
  const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
  const theme = createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
      },
    },
    typography: {
      fontFamily: `'Inter', sans-serif`,
      h1: {
        fontSize: '2.25rem',
        fontWeight: 700,
        lineHeight: 1.2,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        color: '#1a202c',
      },
      button: {
        textTransform: 'none',
        fontWeight: 600,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontSize: '1rem',
            height: '40px',
          },
          input: {
            height: '40px',
            padding: '0px 16px !important',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E2E8F0',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            padding: '8px 16px',
            fontWeight: 600,
            textTransform: 'none',
          },
        },
      },
    },
  });

  useEffect(() => {
    setShow(onShow);
  }, [onShow]);

  const [show, setShow] = useState(onShow);
  const handleToggleShow = () => {
    toggleShow(entry.id);
  };

  return (
    <React.Fragment key={entry.id}>
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        mb={'1rem'}
        px={'25px'}
        minH="60px"
      >
        <Flex alignItems="center" flexDirection="row" me="14px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {'Registro ' + entry.id}
          </Text>
          <Icon
            onClick={handleToggleShow}
            as={show ? GoChevronDown : GoChevronUp}
            color={textColor}
            w="24px"
            h="24px"
            ml={'10px'}
            fontWeight="700"
            cursor={'pointer'}
          />
        </Flex>
        <Tooltip hasArrow label="Eliminar registro" bg="gray.300" color="black">
          <Button onClick={() => deleteEntry(entry.id)}>
            <IoRemoveCircleOutline fontSize="1.5rem" />
          </Button>
        </Tooltip>
      </Flex>
      {show && (
        <React.Fragment>
          <SimpleGrid
            columns={{ base: '1', md: '2', lg: '2' }}
            gap={{ sm: '10px', md: '26px' }}
            mb="1rem"
            px="20px"
          >
            {fields.map((field) => (
              <Flex direction="column" key={field.value}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  {field.label}{' '}
                  {field.value === 'microorganismos' ||
                    (field.value === 'lote' && (
                      <RegisterMicroorganismo bgButtonColor={modalButtonBg} />
                    ))}
                </FormLabel>

                {field.type === 'date' && (
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(entry[field.value])}
                        onChange={(newValue) =>
                          handleChange(
                            category,
                            field.value,
                            newValue.format('YYYY-MM-DD'),
                            entry.id,
                          )
                        }
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                )}

                {field.type === 'time' && (
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(entry[field.value], 'hh:mm A')}
                        onChange={(newValue) =>
                          handleChange(
                            category,
                            field.value,
                            newValue.format('hh:mm A'),
                            entry.id,
                          )
                        }
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                )}

                {field.type === 'dropdown' && (
                  <ThemeProvider theme={theme}>
                    <Select
                      multiple={field.multiple}
                      sx={{ height: '40px', borderRadius: '8px' }}
                      value={entry[field.value] || []}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span
                              style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontFamily: 'inherit',
                              }}
                            >
                              {field.label}
                            </span>
                          );
                        }
                        return field.multiple
                          ? [...selected].join(', ')
                          : selected;
                      }}
                      onChange={(e) =>
                        handleChange(
                          category,
                          field.value,
                          e.target.value,
                          entry.id,
                        )
                      }
                      onFocus={handleFocus}
                      variant="outlined"
                    >
                      {field.options.map((option) => (
                        <MenuItem key={option} value={option}>
                          <MUICheckbox
                            checked={
                              entry[field.value]?.includes(option) ?? false
                            }
                          />
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                )}

                {field.type === 'weight' && (
                  <InputGroup>
                    <Input
                      value={entry[field.value] || ''}
                      onChange={(e) =>
                        handleChange(
                          category,
                          field.value,
                          extractNumericValue(e.target.value),
                          entry.id,
                        )
                      }
                      onFocus={handleFocus}
                      borderRadius="6px"
                      height="40px"
                      isRequired
                      fontSize="md"
                      placeholder={field.label}
                      variant="auth"
                      size="lg"
                    />
                    {field?.unit && (
                      <InputRightAddon color={textColor}>
                        {field.unit}
                      </InputRightAddon>
                    )}
                  </InputGroup>
                )}

                {(field.type === 'text' || field.type === 'number') && (
                  <InputGroup>
                    <Input
                      value={entry[field.value] || ''}
                      onChange={(e) =>
                        handleChange(
                          category,
                          field.value,
                          field.type === 'number'
                            ? extractNumericValue(e.target.value)
                            : e.target.value,
                          entry.id,
                        )
                      }
                      onFocus={handleFocus}
                      borderRadius="6px"
                      height="40px"
                      isRequired
                      fontSize="md"
                      placeholder={field.label}
                      variant="auth"
                      size="lg"
                    />
                    {field?.unit && (
                      <InputRightAddon color={textColor}>
                        {field.unit}
                      </InputRightAddon>
                    )}
                  </InputGroup>
                )}
              </Flex>
            ))}
          </SimpleGrid>
          <SimpleGrid
            columns={{ base: '1', md: '1', lg: '1' }}
            gap="26px"
            my="1rem"
            px="20px"
          >
            <Flex direction="column">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Observaciones
              </FormLabel>
              <Textarea
                placeholder="Escribe aquí las observaciones del proceso."
                value={entry.observaciones || ''}
                onChange={(e) =>
                  handleChange(
                    category,
                    'observaciones',
                    e.target.value,
                    entry.id,
                  )
                }
              />
            </Flex>
          </SimpleGrid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
