import { Tooltip, useColorModeValue } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { esES } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { IoEyeOutline } from 'react-icons/io5';
import { formatTableValue } from 'utils/format';

export default function DataTableEntradas({
  columns,
  rows,
  editFunction,
  seeFunction,
}) {
  const [pageSize, setPageSize] = useState(5);
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const currentYear = new Date().getFullYear().toString();
  const hasCosechaColumn = columns.some(
    (col) => col.field.toLowerCase() === 'cosecha',
  );
  const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
  const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
  const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
  const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
  const theme = createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: '1rem',
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
            borderRadius: '2rem',
            backgroundColor: balanceBg,
            fontWeight: 600,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
          },
          columnHeaders: {
            color: textPrimaryColor,
            backgroundColor: balanceBg,
            fontWeight: 600,
            border: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: balanceBg,
            color: textPrimaryColor,
          },
        },
      },
    },
  });

  const processedRows = useMemo(() => {
    if (rows?.length > 0 && columns?.length > 0) {
      // Sort rows so all parent rows (ending with .0) come first
      const sortedRows = [...rows].sort((a, b) => {
        const aParts = a.registerId.split('.').map(Number);
        const bParts = b.registerId.split('.').map(Number);

        // Compare major version (e.g., 2 vs 1) in reverse
        if (aParts[0] !== bParts[0]) return bParts[0] - aParts[0];

        // Compare minor version (e.g., 1 vs 0)
        return (aParts[1] || 0) - (bParts[1] || 0);
      });

      // Map to store parent paths
      const parentPaths = {};

      // Process rows to build paths
      const formattedRows = sortedRows.map((row) => {
        const { registerId } = row;
        const idParts = registerId.split('.');

        // If it's a parent row (ending with .0)
        if (idParts[1] === '0') {
          parentPaths[idParts[0]] = registerId.toString(); // Store the parent path
          return { ...row, path: registerId.toString() };
        }

        // If it's a child row
        const parentId = idParts[0];
        const parentPath = parentPaths[parentId] || registerId;

        return {
          ...row,
          path: `${parentPath}/${registerId}`, // Construct the full path
        };
      });

      // Format rows for display
      return formattedRows.map((row) => {
        const formattedRow = { ...row };
        columns.forEach((column) => {
          if (formattedRow[column.field] !== undefined) {
            const isParent = formattedRow?.registerId?.split('.')[1] === '0';
            formattedRow[column.field] = formatTableValue(
              column.dataType,
              formattedRow[column.field],
              isParent,
            );
          } else {
            formattedRow[column.field] = '-';
          }
        });
        return formattedRow;
      });
    }
    return [];
  }, [rows, columns]);

  const processedColumns = useMemo(() => {
    return [
      {
        headerName: 'Acciones',
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip
                  label={'Editar entrada'}
                  aria-label={'Editar entrada'}
                  bg="black"
                  color="white"
                  padding="10px"
                  borderRadius="10px"
                >
                  <span>
                    <GoPencil color={textPrimaryColor} />
                  </span>
                </Tooltip>
              }
              label={'Editar entrada'}
              onClick={() => editFunction(params)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip
                  label={'Ver detalle'}
                  aria-label={'Ver detalle'}
                  bg="black"
                  color="white"
                  padding="10px"
                  borderRadius="10px"
                >
                  <span>
                    <IoEyeOutline color={textPrimaryColor} />
                  </span>
                </Tooltip>
              }
              label={'Ver detalle'}
              onClick={() => seeFunction(params)}
            />,
          ];
        },
      },
      ...columns,
    ];
  }, [columns]);

  const getTreeDataPath = (row) => row?.path?.split('/');

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGridPro
          treeData
          getTreeDataPath={getTreeDataPath}
          initialState={{
            pinnedColumns: {
              left: [GRID_TREE_DATA_GROUPING_FIELD, 'actions'],
              right: [],
            },
            ...(hasCosechaColumn && {
              filter: {
                filterModel: {
                  items: [
                    {
                      id: 1,
                      field: 'cosecha',
                      operator: 'contains',
                      value: currentYear,
                    },
                  ],
                },
              },
            }),
          }}
          rows={processedRows}
          columns={processedColumns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          headerFilters
          disableDensitySelector
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            '& .MuiDataGrid-container--top [role=row]': {
              backgroundColor: balanceBg,
              color: textPrimaryColor,
            },
            '& .MuiButtonBase-root.MuiIconButton-root': {
              color: textPrimaryColor,
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
}
