export const themeMUI = (
  mainColor,
  secondaryColor,
  textPrimaryColor,
  textSecondaryColor,
) => {
  return {
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
      },
    },
    typography: {
      fontFamily: `'Inter', sans-serif`, // Chakra UI's default font
      h1: {
        fontSize: '2.25rem', // Match Chakra's Heading
        fontWeight: 700,
        lineHeight: 1.2,
      },
      body1: {
        fontSize: '1rem', // Match Chakra's default text
        lineHeight: 1.5,
        color: '#1a202c', // Chakra's gray.900
      },
      button: {
        textTransform: 'none', // Match Chakra's no-uppercase style for buttons
        fontWeight: 600,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontSize: '1rem',
            height: '40px',
          },
          input: {
            height: '40px',
            padding: '0px 16px !important',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E2E8F0', // Chakra's blue.500 for focus state
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            padding: '8px 16px',
            fontWeight: 600,
            textTransform: 'none',
          },
        },
      },
    },
  };
};
