import {
  CircularProgress,
  Flex,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { addCatacion, editCatacion, getAllCatacion } from 'api/catacion';
import { cafeService } from 'api/services/cafeService';
import { origenService } from 'api/services/origenService';
import SelectCommunityPerfil from 'components/modal/communityPerfil/SelectCommunityPerfil';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { ALERT, CATACION } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MuestrasPanel from 'views/admin/seco/catacion/components/MuestrasPanel/MuestrasPanel';
import ScoreModal from 'views/admin/seco/catacion/modals/ScoreModal';
import FlavorWheel from './components/FlavorWheel';

export default function CatacionMuestras() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const location = useLocation();
  const { selectedCatacion } = location.state || {};

  const selectedFlavorsInitialState = selectedCatacion?.perfiles ?? [];
  const catacionObj = {
    folioSecado: 0,
    claveDescarga: '',
    entradaId: 0,
    fecha: '',
    puntaje: '',
    calificaciones: {
      fragancia: 6,
      sabor: 6,
      saborResidual: 6,
      acidez: 6,
      intensidad: 'Alta',
      cuerpo: 6,
      nivel: 'Pesado',
      uniformidad: 'Uniforme',
      balance: 6,
      taza: 'Limpia',
      dulzor: 'Dulce',
    },
    perfiles: '',
    estado: '',
    region: '',
    municipio: '',
    comunidad: '',
    productor: '',
    tipo: '',
    cliente: '',
    caracteristica: '',
    clasificacion: 'muestra',
    createdAt: '',
    createdBy: '',
  };
  const catacionInitialState = selectedCatacion ?? catacionObj;

  const [flavors, setFlavors] = useState([]);
  const [selectedFlavors, setSelectedFlavors] = useState(
    selectedFlavorsInitialState,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [catacion, setCatacion] = useState(catacionInitialState);
  const [origen, setOrigen] = useState([]);
  const [perfilsByCommunity, setPerfilsByCommunity] = useState([]);

  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });
  const [scoreModal, setScoreModal] = useState({
    state: false,
  });
  const [communityModal, setCommunityModal] = useState({
    state: false,
    title: '',
    subtitle: '',
    communities: [],
  });

  useEffect(() => {
    const fetchAllPerfil = async () => {
      try {
        setIsLoading(true);
        const response = await cafeService.getAllPerfil(TOKEN);
        if (response.status === 200) {
          const resultData = response?.data?.data?.data || [];
          setFlavors(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchAllOrigen = async () => {
      try {
        setIsLoading(true);
        const response = await origenService.getAllOrigen(TOKEN);
        if (response.status === 200) {
          const resultData = response?.data?.data?.data || [];
          setOrigen(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchPerfilsByCommunity = async () => {
      try {
        setIsLoading(true);
        const response = await getAllCatacion(TOKEN);
        const result = await response.json();
        if (response.status === 200 && result.status === 'SUCCESS') {
          const resultData =
            result?.data?.data.filter((x) => x.folioSecado === 0) || [];
          setPerfilsByCommunity(resultData);
        } else {
          handleErrorMessage();
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllPerfil();
    fetchAllOrigen();
    fetchPerfilsByCommunity();
  }, []);

  const handleRenderFlavorItem = async (backgroundColor) => {
    let isComunidadSelected = false;
    setCatacion((prevState) => {
      if (!prevState.comunidad) {
        handleMessage(
          'error',
          'Seleccione una comunidad.',
          'Favor de seleccionar una comunidad antes de asignar perfiles.',
        );
        setIsSubmitted(true);
      } else {
        isComunidadSelected = true;
      }
      return prevState;
    });

    if (backgroundColor && isComunidadSelected) {
      const selection = flavors.find((x) => x.hexcode === backgroundColor);
      if (selection?.nombre && selection?.hexcode) {
        setSelectedFlavors((prevState) => {
          const isAlreadySelected = prevState.some(
            (flavor) => flavor.hexcode === selection.hexcode,
          );
          if (!isAlreadySelected) {
            return [...prevState, selection];
          } else {
            handleMessage(
              'error',
              'Este perfil ya ha sido seleccionado.',
              'Favor de seleccionar otro.',
            );
          }
          return prevState;
        });
      }
    }
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleErrorMessage = () => {
    handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
  };

  const handleCloseCommunityModal = () => {
    setCommunityModal({
      state: false,
      title: '',
      subtitle: '',
      communities: [],
    });
  };

  const handleOpenScoreModal = () => {
    setScoreModal({
      state: true,
    });
  };

  const handleCloseScoreModal = () => {
    setScoreModal({
      state: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!catacion.comunidad) {
      setIsLoading(false);
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        'Favor de seleccionar una comunidad.',
      );
      return;
    }

    if (
      !catacion.puntaje &&
      typeof +catacion.puntaje === 'number' &&
      !isNaN(+catacion.puntaje)
    ) {
      setIsLoading(false);
      handleMessage('error', ALERT.ERROR_TITLE, CATACION.ERROR_PUNTAJE);
      return;
    } else if (Math.abs(+catacion.puntaje) < 73) {
      handleMessage('error', ALERT.ERROR_TITLE, CATACION.ERROR_MIN);
      return;
    } else if (Math.abs(+catacion.puntaje) > 99) {
      handleMessage('error', ALERT.ERROR_TITLE, CATACION.ERROR_MAX);
      return;
    }

    if (selectedFlavors.length > 0) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);
        catacion.perfiles =
          selectedFlavors.map((obj) => obj.id).join(',') || [];
        const response =
          selectedCatacion && catacion.id
            ? await editCatacion(TOKEN, USERID, catacion, catacion.id)
            : await addCatacion(TOKEN, USERID, catacion);

        const result = await response.json();

        if (response.status === 200 && result.status === 'SUCCESS') {
          setIsSubmitted(false);
          setCatacion(catacionObj);
          setSelectedFlavors([]);
          handleMessage('success', 'Operación exitosa.', CATACION.SUCCESS);
          window.scrollTo({ top: 0, left: 0 });
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleErrorMessage();
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmitted(true);
      handleErrorMessage();
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      maxHeight={'85vh'}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
          >
            {!isLoading && flavors?.length > 0 && (
              <FlavorWheel
                flavors={flavors}
                handleRenderFlavorItem={handleRenderFlavorItem}
              />
            )}
            {isLoading && (
              <Flex mb="5" justifyContent={'center'} alignItems={'center'}>
                <CircularProgress isIndeterminate color={textColorBrand} />
              </Flex>
            )}
          </Flex>
          <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            <MuestrasPanel
              selectedFlavors={selectedFlavors}
              setSelectedFlavors={setSelectedFlavors}
              catacion={catacion}
              setCatacion={setCatacion}
              origen={origen}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              isSubmitted={isSubmitted}
              setCommunityModal={setCommunityModal}
              perfilsByCommunity={perfilsByCommunity}
              handleOpenScoreModal={handleOpenScoreModal}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
      <SelectCommunityPerfil
        isOpen={communityModal.state}
        onClose={handleCloseCommunityModal}
        communityModal={communityModal}
        setSelectedFlavors={setSelectedFlavors}
        availableFlavors={flavors}
      />
      <ScoreModal
        isOpen={scoreModal.state}
        onClose={handleCloseScoreModal}
        catacion={catacion}
        setCatacion={setCatacion}
      />
    </Flex>
  );
}
