// Custom components
import { Divider, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import Aromas from 'views/admin/seco/catacion/components/SelectionsPanel/components/Aromas';
import Calificaciones from 'views/admin/seco/catacion/components/SelectionsPanel/components/Calificaciones';
import Folio from 'views/admin/seco/catacion/components/SelectionsPanel/components/Folio';
import Header from 'views/admin/seco/catacion/components/SelectionsPanel/components/Header';

export default function SelectionsPanel({
  selectedFlavors,
  setSelectedFlavors,
  catacion,
  setCatacion,
  secado,
  handleFocus,
  isSubmitted,
  isLoading,
  handleSubmit,
  setCommunityModal,
  perfilsByCommunity,
  handleOpenScoreModal,
}) {
  const paleGray = useColorModeValue('secondaryGray.700', 'whiteAlpha.100');

  return (
    <Card maxHeight="85vh" overflowY="auto">
      <Header selectedFlavors={selectedFlavors} />
      <Folio
        catacion={catacion}
        setCatacion={setCatacion}
        secado={secado}
        handleFocus={handleFocus}
        isSubmitted={isSubmitted}
      />
      <Divider w="100%" alignSelf="center" my="30px" borderColor={paleGray} />
      <Calificaciones
        catacion={catacion}
        setCatacion={setCatacion}
        handleFocus={handleFocus}
        isSubmitted={isSubmitted}
        handleOpenScoreModal={handleOpenScoreModal}
      />
      <Divider w="100%" alignSelf="center" my="30px" borderColor={paleGray} />
      <Aromas
        selectedFlavors={selectedFlavors}
        setSelectedFlavors={setSelectedFlavors}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        setCommunityModal={setCommunityModal}
        perfilsByCommunity={perfilsByCommunity}
      />
    </Card>
  );
}
