import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import dayjs from 'dayjs';
import { useState } from 'react';
import { MdOutlineMonitorWeight } from 'react-icons/md';

// MUI imports
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import MultipleForm from 'views/admin/humedo/fermentacion/components/MultipleForm';

export default function FermentacionModuleMultiple({
  title,
  icon,
  handleSubmit,
  handleChange,
  handleFocus,
  fields,
  fermentacion,
  setFermentacion,
  setConfirm,
  loading,
  category,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const [collapsed, setCollapsed] = useState(false);

  const toggleAllOnShow = () => {
    setFermentacion((prevState) => {
      // Check if prevState[category] is an array
      if (!Array.isArray(prevState?.[category])) {
        return prevState; // Return previous state if it's not an array
      }
      return {
        ...prevState,
        [category]: prevState?.[category]?.map((entry) => ({
          ...entry,
          onShow: !collapsed,
        })),
      };
    });

    setCollapsed(!collapsed);
  };

  const toggleShow = (id) => {
    setFermentacion((prevState) => {
      // Check if prevState[category] is an array
      if (!Array.isArray(prevState?.[category])) {
        return prevState; // Return previous state if it's not an array
      }
      return {
        ...prevState,
        [category]: prevState?.[category]?.map((entry) => ({
          ...entry,
          onShow: entry.id === id ? !entry.onShow : false,
        })),
      };
    });
  };

  const addEntry = () => {
    const addNewEntry = () => {
      const currentEntries = fermentacion[category] || [];
      const nextId =
        currentEntries.length > 0
          ? Math.max(...currentEntries.map((entry) => entry.id)) + 1
          : 1;

      // Create new entry with initialized fields
      const newEntry = { id: nextId, onShow: false };
      fields.forEach((field) => {
        switch (field.type) {
          case 'number':
            newEntry[field.value] = '0.00';
            break;
          case 'weight':
            newEntry[field.value] = fermentacion?.pesoTotal || '0.00';
            break;
          case 'time':
            newEntry[field.value] = dayjs();
            break;
          case 'date':
            newEntry[field.value] = dayjs();
            break;
          default:
            newEntry[field.value] = '';
        }
      });
      newEntry.observaciones = '';
      setFermentacion((prev) => ({
        ...prev,
        [category]: [...currentEntries, newEntry],
      }));
      setConfirm({ state: false });
    };

    setConfirm({
      state: 'true',
      title: 'Añadir registro',
      subtitle: `¿Estás seguro de querer añadir un nuevo registro?`,
      icon: <Icon as={IoAddCircleOutline} w="24px" h="24px" />,
      primaryBtnText: 'Confirmar',
      primaryBtnAction: addNewEntry,
      secondaryBtnText: 'Volver',
      secondaryBtnAction: () => setConfirm({ state: false }),
    });
  };

  const deleteEntry = (idToDelete) => {
    const confirmDelete = () => {
      setFermentacion((prev) => ({
        ...prev,
        [category]: prev[category].filter((entry) => entry.id !== idToDelete),
      }));
      setConfirm({ state: false });
    };

    setConfirm({
      state: 'true',
      title: 'Eliminar registro',
      subtitle: `¿Estás seguro de querer eliminar este registro?`,
      icon: <Icon as={IoTrashOutline} w="24px" h="24px" />,
      primaryBtnText: 'Eliminar',
      primaryBtnAction: confirmDelete,
      secondaryBtnText: 'Cancelar',
      secondaryBtnAction: () => setConfirm({ state: false }),
    });
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        minH={'80px'}
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <Icon
              as={icon || MdOutlineMonitorWeight}
              width="25px"
              height="25px"
              color="white"
            />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              {title || 'Módulo de fermentación'}
            </Text>
          </Flex>
          <Flex gap={'30px'} alignItems={'center'}>
            {fermentacion.entradaId && (
              <Button
                onClick={handleSubmit}
                isLoading={loading}
                loadingText={'Realizando registro...'}
                colorScheme="green"
              >
                Registrar
              </Button>
            )}
            <Tooltip
              hasArrow
              label="Añadir registro"
              bg="gray.300"
              color="black"
            >
              <Button onClick={addEntry} w={'56px'}>
                <IoAddCircleOutline fontSize="1.5rem" />
              </Button>
            </Tooltip>
            <Tooltip
              hasArrow
              label={collapsed ? 'Contraer' : 'Expandir'}
              bg="gray.300"
              color="black"
            >
              <Button onClick={toggleAllOnShow} w={'56px'}>
                {collapsed ? (
                  <ChevronDownIcon fontSize="1.2rem" />
                ) : (
                  <ChevronUpIcon fontSize="1.2rem" />
                )}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      {collapsed &&
        fermentacion[category]?.length > 0 &&
        fermentacion[category]?.map((entry) => (
          <MultipleForm
            entry={entry}
            handleChange={handleChange}
            handleFocus={handleFocus}
            fields={fields}
            category={category}
            deleteEntry={deleteEntry}
            onShow={entry.onShow}
            toggleShow={toggleShow}
          />
        ))}
      {collapsed &&
        (fermentacion[category]?.length === 0 || !fermentacion[category]) && (
          <Flex justifyContent={'center'} py={'50px'}>
            <Text
              color={textColor}
              fontSize="18px"
              fontWeight="400"
              lineHeight="100%"
            >
              Favor de añadir registros.
            </Text>
          </Flex>
        )}
    </Card>
  );
}
