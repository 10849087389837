import {
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Perfil from 'components/card/Perfil';

export default function Header({ selectedFlavors }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const handlePerfilRendering = (type, item) => {
    let flavor = item ? item : selectedFlavors[selectedFlavors.length - 1];

    switch (type) {
      case 'name': {
        return flavor?.nombre ? flavor.nombre : 'Nombre del perfil';
      }
      case 'hexcode': {
        return flavor?.hexcode ? flavor.hexcode : '#000';
      }
      case 'group': {
        const selectedFlavors = [
          flavor?.categoriaA,
          flavor?.categoriaB,
          flavor?.categoriaC,
          flavor?.categoriaD,
        ]
          .filter(Boolean)
          .join(', ');

        return selectedFlavors ? selectedFlavors : '-';
      }
      case 'type': {
        return flavor?.tipo ? flavor.tipo : '-';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Flex direction="column">
      <Flex justify="space-between" px="10px" pt="5px" mb="25px" align="center">
        <Text
          color={textColor}
          fontSize="lg"
          fontWeight="700"
          lineHeight="100%"
        >
          Selección de perfil
        </Text>
      </Flex>
      <Tabs>
        <TabPanels mb="20px">
          <TabPanel p="0px">
            <Perfil
              name={handlePerfilRendering('name')}
              hexcode={handlePerfilRendering('hexcode')}
              group={handlePerfilRendering('group')}
              type={handlePerfilRendering('type')}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
