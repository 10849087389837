import {
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { cafeService } from 'api/services/cafeService';
import ConfirmModal from 'components/modal/ConfirmModal';
import { ALERT } from 'constant/constant';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cafeActions } from 'store/cafe';
import {
  handleChange,
  handleCloseConfirmModal,
  handleFocus,
  handleSubmit,
} from 'utils/hooks';
import ResultMessage from '../ResultMessage';

export default function RegisterMicroorganismo(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const storeMicroorganismo = useSelector((state) => state.cafe.microorganismo);
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  const initialStateMicroorganismo = {
    clave: '',
    nombre: '',
    responsable: '',
    tipo: 'Individual',
    origen: '',
    notas: '',
  };
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const tipos = ['Individual', 'Combinada', 'Otro'];
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState({ state: false });
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });
  const [microorganismo, setMicroorganismo] = useState(
    initialStateMicroorganismo,
  );

  const handleCloseModal = () => {
    if (message.state === 'success') {
      onClose();
    }
    setMicroorganismo(initialStateMicroorganismo);
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleMicroorganismoSubmit = async (e) => {
    handleSubmit({
      e,
      apiCall: () =>
        cafeService.addMicroorganismo(TOKEN, USERID, microorganismo),
      data: microorganismo,
      requiredFields: [],
      setLoading: setIsLoading,
      setSubmitted: setIsSubmitted,
      setMessage,
      setConfirm,
      alertMessages: ALERT,
      onSuccess: () => {
        dispatch(
          cafeActions.setMicroorganismoData({
            microorganismo: [...(storeMicroorganismo || []), microorganismo],
          }),
        );
        setMicroorganismo(initialStateMicroorganismo);
      },
    });
  };

  return (
    <>
      <Button
        bg={props.bgButtonColor}
        color="white"
        fontSize="11px"
        fontWeight="500"
        py="4px"
        px="8px"
        ml="6px"
        height="auto"
        borderRadius="10px"
        onClick={onOpen}
      >
        {props.label ?? ' Añadir + '}
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar nuevo microorganismo</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {!isLoading && !message.state && (
              <FormControl>
                <FormLabel>Clave</FormLabel>
                <Input
                  borderColor={
                    isSubmitted && !microorganismo.clave ? 'red.500' : null
                  }
                  value={microorganismo.clave}
                  onChange={(e) =>
                    handleChange('clave', e.target.value, setMicroorganismo)
                  }
                  handleFocus={() => handleFocus(setMessage, setIsSubmitted)}
                  borderRadius="6px"
                  height="40px"
                  isRequired={true}
                  fontSize="md"
                  placeholder="Clave"
                  variant="auth"
                  size="lg"
                />
                <FormLabel mt="10px">Nombre</FormLabel>
                <Input
                  borderColor={
                    isSubmitted && !microorganismo.nombre ? 'red.500' : null
                  }
                  value={microorganismo.nombre}
                  onChange={(e) =>
                    handleChange('nombre', e.target.value, setMicroorganismo)
                  }
                  handleFocus={() => handleFocus(setMessage, setIsSubmitted)}
                  borderRadius="6px"
                  height="40px"
                  isRequired={true}
                  fontSize="md"
                  placeholder="Nombre"
                  variant="auth"
                  size="lg"
                />
                <FormLabel mt="10px">Origen (opcional)</FormLabel>
                <Input
                  borderColor={
                    isSubmitted && !microorganismo.origen ? 'red.500' : null
                  }
                  value={microorganismo.origen}
                  onChange={(e) =>
                    handleChange('origen', e.target.value, setMicroorganismo)
                  }
                  handleFocus={() => handleFocus(setMessage, setIsSubmitted)}
                  borderRadius="6px"
                  height="40px"
                  isRequired={true}
                  fontSize="md"
                  placeholder="Origen"
                  variant="auth"
                  size="lg"
                />
                <FormLabel mt="10px">Responsable (opcional)</FormLabel>
                <Input
                  borderColor={
                    isSubmitted && !microorganismo.responsable
                      ? 'red.500'
                      : null
                  }
                  value={microorganismo.responsable}
                  onChange={(e) =>
                    handleChange(
                      'responsable',
                      e.target.value,
                      setMicroorganismo,
                    )
                  }
                  handleFocus={() => handleFocus(setMessage, setIsSubmitted)}
                  borderRadius="6px"
                  height="40px"
                  isRequired={true}
                  fontSize="md"
                  placeholder="Responsable"
                  variant="auth"
                  size="lg"
                />
                <FormLabel mt="10px">Tipo</FormLabel>
                <Select
                  borderColor={
                    isSubmitted && !microorganismo.tipo ? 'red.500' : null
                  }
                  value={microorganismo.tipo}
                  onChange={(e) => {
                    handleChange('tipo', e.target.value, setMicroorganismo);
                  }}
                  onFocus={() => handleFocus(setMessage, setIsSubmitted)}
                  variant="auth"
                >
                  {tipos.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            {isLoading && !message.state && (
              <Flex
                width={'100%'}
                height={'200px'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={'10px'}
              >
                <Text>Cargando...</Text>
                <CircularProgress
                  isIndeterminate
                  color={props.bgButtonColor}
                  size={50}
                />
              </Flex>
            )}
            <ResultMessage
              isOpen={message.state}
              onClose={() => handleCloseModal(setMessage)}
              message={message}
            />
            <ConfirmModal
              isOpen={confirm.state}
              onClose={() => handleCloseConfirmModal(setConfirm)}
              confirm={confirm}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleMicroorganismoSubmit}
              variant="brand"
              color="white"
              mr={3}
            >
              Registrar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
