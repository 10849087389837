// Chakra imports
import {
  Checkbox,
  Flex,
  FormLabel,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { formatTableValue } from 'utils/format';

export default function PesoPrintable({ weightEntries }) {
  const balanceBg = useColorModeValue('brand.900', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const vehicleForm = (weightEntry) => {
    if (weightEntry?.registerId?.includes('.0')) return;

    const weightEntryValues = [
      {
        title: 'Operador / Chofer',
        value: weightEntry?.chofer || 'No especificado',
      },
      { title: 'Placas', value: weightEntry?.placas || 'No especificado' },
      { title: 'Marca', value: weightEntry?.marca || 'No especificado' },
      {
        title: 'Número de unidad',
        value: weightEntry?.unidad || 'No especificado',
      },
    ];

    return (
      <>
        <SimpleGrid
          columns={{ base: '4', md: '4', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          {weightEntryValues.map((x, index) => (
            <Flex key={index} direction="column" mb="1rem">
              <Flex>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{x.value}</Text>
            </Flex>
          ))}
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '2' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
          px={'2rem'}
        >
          <Flex direction="column" mb="1rem">
            <Text fontSize="md" fontWeight="500" color={textColor}>
              Camión en condiciones de sanidad exterior e interior, en piso
              cubierta y techo
            </Text>
          </Flex>
          <Flex direction="column" mb="1rem">
            <Stack spacing={5} direction="row">
              <Checkbox
                isChecked={weightEntry?.cumple === 'Cumple'}
                color={textColor}
              >
                Cumple
              </Checkbox>
              <Checkbox
                isChecked={weightEntry?.cumple === 'No cumple'}
                color={textColor}
              >
                No cumple
              </Checkbox>
            </Stack>
          </Flex>
        </SimpleGrid>
      </>
    );
  };

  const pesoForm = (weightEntry) => {
    const isParent = weightEntry?.registerId?.includes('.0') ? true : false;
    const weightEntryValues = [
      {
        title: 'Característica de café',
        value: weightEntry?.caracteristica || 'No especificado',
        type: '',
        exclude: false,
      },
      {
        title: 'Número de ticket báscula',
        value: weightEntry?.ticketBascula || 'No especificado',
        type: '',
        exclude: isParent,
      },
      {
        title: 'Peso con carga',
        value: weightEntry?.pesoConCarga || 'No especificado',
        type: weightEntry?.pesoConCarga ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Peso sin carga',
        value: weightEntry?.pesoSinCarga || 'No especificado',
        type: weightEntry?.pesoSinCarga ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Yutes',
        value: weightEntry?.yutes || 'No especificado',
        type: '',
        exclude: false,
      },
      {
        title: 'Lonas',
        value: weightEntry?.lonas || 'No especificado',
        type: '',
        exclude: false,
      },
      {
        title: 'Cajas',
        value: weightEntry?.cajas || 'No especificado',
        type: '',
        exclude: false,
      },
      {
        title: 'Tara',
        value: weightEntry?.tara || 'No especificado',
        type: weightEntry?.tara ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Peso bruto',
        value: weightEntry?.pesoBruto || 'No especificado',
        type: weightEntry?.pesoBruto ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Peso neto',
        value: weightEntry?.pesoNeto || 'No especificado',
        type: weightEntry?.pesoNeto ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Peso por saco',
        value: weightEntry?.pesoPorSaco || 'No especificado',
        type: weightEntry?.pesoPorSaco ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Porcentaje límite de humedad',
        value: weightEntry?.limiteHumedad || '11%',
        type: weightEntry?.limiteHumedad ? 'percentage' : '',
        exclude: isParent,
      },
      {
        title: 'Porcentaje de humedad capturado',
        value:
          weightEntry?.humedad !== '0.00'
            ? weightEntry?.humedad
            : weightEntry?.limiteHumedad
            ? weightEntry?.limiteHumedad
            : '11%',
        type: weightEntry?.humedad ? 'percentage' : '',
        exclude: isParent,
      },
      {
        title: 'Descuento humedad (MXN)',
        value: weightEntry?.descuentoHumedad || 'No especificado',
        type: weightEntry?.descuentoHumedad ? 'pesos' : '',
        exclude: false,
      },
      {
        title: 'Descuento humedad (KG)',
        value: weightEntry?.descuentoHumedadPeso || 'No especificado',
        type: weightEntry?.descuentoHumedadPeso ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Precio por KG',
        value: weightEntry?.precioKg || 'No especificado',
        type: weightEntry?.precioKg ? 'pesos' : '',
        exclude: false,
      },
      {
        title: 'Peso neto a pagar',
        value:
          weightEntry?.pesoNeto - weightEntry?.descuentoHumedadPeso ||
          'No especificado',
        type: weightEntry?.precioTotal ? 'kilograms' : '',
        exclude: false,
      },
      {
        title: 'Total a pagar',
        value: weightEntry?.precioTotal || 'No especificado',
        type: weightEntry?.precioTotal ? 'pesos' : '',
        exclude: false,
      },
      {
        title: 'Observaciones',
        value: weightEntry?.observaciones || 'No especificado',
        type: '',
        exclude: isParent,
      },
    ];

    return (
      <SimpleGrid
        columns={{ base: '4', md: '4', lg: '4' }}
        gap={{ sm: '10px', md: '26px' }}
        mb={'1rem'}
        px={'2rem'}
      >
        {weightEntryValues.map((x, index) =>
          !x.exclude ? (
            <Flex key={index} direction="column" mb="1rem">
              <Flex>
                <FormLabel
                  display="flex"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                >
                  {x.title}
                </FormLabel>
              </Flex>
              <Text>{formatTableValue(x.type, x.value)}</Text>
            </Flex>
          ) : null,
        )}
      </SimpleGrid>
    );
  };

  return (
    <Card direction="column" w="100%">
      {weightEntries?.map((entry) =>
        entry.map((x) => (
          <Flex direction="column" key={x.id}>
            <Flex
              justify="space-between"
              p="20px"
              mb="20px"
              borderRadius="16px"
              borderWidth="2px"
              borderColor={balanceBg}
              bgImage={balanceImg}
              bgPosition="right"
              bgSize="cover"
              className="printable-subheader"
            >
              <Flex align="center" justify="space-between" w="100%">
                <Flex gap="15px" flexDirection="row" me="14px">
                  <Text
                    color={balanceBg}
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    {x?.registerId?.includes('.0')
                      ? 'Recuento total de entrada'
                      : `Registro ${x?.registerId?.split('.')[1] || ''}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {vehicleForm(x)}
            {pesoForm(x)}
          </Flex>
        )),
      )}
    </Card>
  );
}
