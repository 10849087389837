import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import { ALERT } from 'constant/constant';

export const handleChange = (field, value, setData) => {
  setData((prevState) => ({
    ...prevState,
    [field]: value,
  }));
};

export const handleMessage = (state, msg, sub, setMessage) => {
  setMessage({
    state: state,
    title: msg,
    subtitle: sub,
  });
};

export const handleFocus = (setMessage, setIsSubmitted) => {
  setMessage({
    state: false,
    title: '',
    subtitle: '',
  });
  setIsSubmitted(false);
};

export const handleCloseModal = (setMessage) => {
  setMessage({
    state: false,
    title: '',
    subtitle: '',
  });
};

export const handleCloseConfirmModal = (setConfirm) => {
  setConfirm({ state: false });
};

export const handleRequiredFields = (fields) => {
  return fields.filter((field) => field.isRequired).map((field) => field.field);
};

export const handleAllTruthy = (obj = {}, required = []) => {
  if (required.length === 0) return true;

  const arrayOfObjects = Array.isArray(obj) ? obj : [obj];

  return arrayOfObjects.every((item) =>
    required.every(
      (key) =>
        item.hasOwnProperty(key) &&
        item[key] !== null &&
        item[key] !== undefined &&
        item[key] !== '',
    ),
  );
};

export const handleSubmit = async ({
  e,
  apiCall,
  data,
  requiredFields,
  setLoading,
  setSubmitted,
  setMessage,
  setConfirm,
  alertMessages,
  onSuccess,
  confirm,
}) => {
  e.preventDefault();

  const confirmSubmit = async () => {
    setConfirm({ state: false });

    const allPropertiesTruthy = handleAllTruthy(data, requiredFields);

    if (allPropertiesTruthy) {
      try {
        setLoading(true);
        setSubmitted(true);

        const response = await apiCall();

        if (response.status === 200 && response.data.status === 'SUCCESS') {
          if (onSuccess) onSuccess();
          setSubmitted(false);
          setLoading(false);
          handleMessage(
            'success',
            alertMessages.SUCCESS_TITLE,
            alertMessages.SUCCESS_SUBTITLE,
            setMessage,
          );
          window.scrollTo({ top: 0, left: 0 });
        } else {
          handleMessage(
            'error',
            alertMessages.ERROR_TITLE,
            alertMessages.ERROR_SUBTITLE,
            setMessage,
          );
        }
      } catch (error) {
        handleMessage(
          'error',
          alertMessages.ERROR_TITLE,
          alertMessages.ERROR_SUBTITLE,
          setMessage,
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setSubmitted(true);
      handleMessage(
        'error',
        alertMessages.ERROR_TITLE,
        alertMessages.ERROR_FIELDS,
        setMessage,
      );
    }
  };

  setConfirm({
    state: true,
    title: confirm?.title || 'Confirmar registro',
    subtitle:
      confirm?.subtitle ||
      `¿Estás seguro de que deseas realizar este registro?`,
    icon: confirm?.icon || <SendIcon w="24px" h="24px" />,
    primaryBtnText: confirm?.primaryBtnText || 'Registrar',
    primaryBtnAction: confirm?.primaryBtnAction || confirmSubmit,
    secondaryBtnText: confirm?.secondaryBtnText || 'Cancelar',
    secondaryBtnAction:
      confirm?.secondaryBtnAction || (() => setConfirm({ state: false })),
  });
};

export const fetchData = async ({
  requests,
  setMessage,
  alertMessages,
  setIsLoading, // Optional loading state handler
}) => {
  try {
    setIsLoading?.(true);
    const responses = await Promise.all(
      requests.map((request) => request.apiCall()),
    );

    responses.forEach((response, index) => {
      const { onSuccess, onError, dataTransform } = requests[index];
      if (response.status === 200) {
        const rawData = response.data;

        const transformedData = dataTransform
          ? dataTransform(rawData)
          : rawData;
        onSuccess?.(transformedData);
      } else {
        onError
          ? onError()
          : handleMessage(
              'error',
              alertMessages.ERROR_TITLE,
              alertMessages.ERROR_SUBTITLE,
              setMessage,
            );
      }
    });
  } catch (error) {
    console.error(error);
    handleMessage(
      'error',
      alertMessages.ERROR_TITLE,
      alertMessages.ERROR_SUBTITLE,
      setMessage,
    );
    requests.forEach((request) => request.onError?.());
  } finally {
    setIsLoading?.(false);
  }
};

export const handleTableEdit = ({
  params,
  tableData,
  setData,
  setMessage,
  setCollapsed,
}) => {
  if (params?.row) {
    const selectedObject = tableData.find((x) => x.id === params?.row?.id);
    setData({ ...selectedObject, editMode: true });
    const container = document.querySelector('.css-8y8lw5');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (setCollapsed) setCollapsed(true);
  } else {
    handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, setMessage);
  }
};

export const handleTableDelete = async ({
  apiCall,
  setLoading,
  setSubmitted,
  setMessage,
  setConfirm,
  onSuccess,
}) => {
  const confirmDelete = async () => {
    setConfirm({ state: false });
    try {
      setLoading(true);
      setSubmitted(true);

      const response = await apiCall();

      if (response.status === 200 && response.data.status === 'SUCCESS') {
        if (onSuccess) onSuccess();
        setSubmitted(false);
        setLoading(false);
        handleMessage(
          'success',
          ALERT.SUCCESS_TITLE,
          ALERT.SUCCESS_SUBTITLE,
          setMessage,
        );
        window.scrollTo({ top: 0, left: 0 });
      } else {
        handleMessage(
          'error',
          ALERT.ERROR_TITLE,
          ALERT.ERROR_SUBTITLE,
          setMessage,
        );
      }
    } catch (error) {
      handleMessage(
        'error',
        ALERT.ERROR_TITLE,
        ALERT.ERROR_SUBTITLE,
        setMessage,
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  setConfirm({
    state: true,
    title: 'Eliminar registro',
    subtitle: `¿Estás seguro de eliminar este registro?`,
    icon: <DeleteOutlineIcon sx={{ width: 28, height: 28 }} />,
    primaryBtnText: 'Eliminar',
    primaryBtnAction: confirmDelete,
    primaryBtnBgColor: 'red.500',
    secondaryBtnText: 'Volver',
    secondaryBtnAction: () => setConfirm({ state: false }),
  });
};
